import { useEffect, useState } from 'react'

interface UseTimeoutTabsType {
  currentStep: number
  currentCount: number
  setCurrentStep: (value: number) => void
  setCurrentCount: (value: number) => void
}

const useTimeoutTabs = (defaultStep?: number): UseTimeoutTabsType => {
  const [currentStep, setCurrentStep] = useState<number>(defaultStep || 1)
  const [currentCount, setCurrentCount] = useState<number>(0)
  const timeCountdown = 5000

  useEffect(() => {
    const timeoutCount = setTimeout(() => {
      if (currentCount > 100) {
        setCurrentCount(0)

        if (currentStep > 2) {
          setCurrentStep(1)
        } else {
          setCurrentStep(currentStep + 1)
        }
      } else {
        setCurrentCount(currentCount + 1)
      }
    }, timeCountdown / 100)

    return () => {
      clearTimeout(timeoutCount)
      if (currentStep > 3) setCurrentCount(-100)
    }
  }, [currentCount, currentStep])

  return {
    currentStep,
    setCurrentStep,
    currentCount,
    setCurrentCount,
  }
}

export default useTimeoutTabs
