import { createSlice } from '@reduxjs/toolkit'

import { sendContactAction } from './contact.action'

const initialState: Contact.ContactState = {
  isLoading: false,
  error: null,
}

export const { actions, reducer } = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendContactAction.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(sendContactAction.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  },
})

export default actions
