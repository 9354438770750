import './style.scss'

import { Progress } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImageAddNewStep1 from '@/assets/img/faceRecognition/img_add_new_step_1.png'
import ImageAddNewStep2 from '@/assets/img/faceRecognition/img_add_new_step_2.png'
import ImageAddNewStep3 from '@/assets/img/faceRecognition/img_add_new_step_3.png'
import ImageFaceRecognitionStep1 from '@/assets/img/faceRecognition/img_face_recognition_step_1.png'
import ImageFaceRecognitionStep2 from '@/assets/img/faceRecognition/img_face_recognition_step_2.png'
import ImageFaceRecognitionStep3 from '@/assets/img/faceRecognition/img_face_recognition_step_3.png'
import IconStep1 from '@/assets/img/faceRecognition/step_1.svg'
import IconStep2 from '@/assets/img/faceRecognition/step_2.svg'
import IconStep3 from '@/assets/img/faceRecognition/step_3.svg'
import { FEATURE_TABS } from '@/contants/face-recognition'
import useTimeoutTabs from '@/hooks/useTimeoutTabs'

type ListTabs = {
  id: number
  icon: string
  image: string
  title: string
  description: string
}

const StepBySteps = () => {
  const { t } = useTranslation(['common'])

  const { currentStep, setCurrentStep, currentCount, setCurrentCount } =
    useTimeoutTabs()
  const [currentTab, setCurrentTab] = useState(FEATURE_TABS.ADD_NEW)

  const listAddNewTabs = [
    {
      id: 1,
      icon: IconStep1,
      image: ImageAddNewStep1,
      title: t('face_recognition_add_new_step_title_1'),
      description: t('face_recognition_add_new_step_description_1'),
    },
    {
      id: 2,
      icon: IconStep2,
      image: ImageAddNewStep2,
      title: t('face_recognition_add_new_step_title_2'),
      description: t('face_recognition_add_new_step_description_2'),
    },
    {
      id: 3,
      icon: IconStep3,
      image: ImageAddNewStep3,
      title: t('face_recognition_add_new_step_title_3'),
      description: t('face_recognition_add_new_step_description_3'),
    },
  ]

  const listFaceRecognitionTabs = [
    {
      id: 1,
      icon: IconStep1,
      image: ImageFaceRecognitionStep1,
      title: t('face_recognition_step_title_1'),
      description: t('face_recognition_step_description_1'),
    },
    {
      id: 2,
      icon: IconStep2,
      image: ImageFaceRecognitionStep2,
      title: t('face_recognition_step_title_2'),
      description: t('face_recognition_step_description_2'),
    },
    {
      id: 3,
      icon: IconStep3,
      image: ImageFaceRecognitionStep3,
      title: t('face_recognition_step_title_3'),
      description: t('face_recognition_step_description_3'),
    },
  ]

  const renderListTabs = (listSteps: ListTabs[]) => {
    return listSteps.map((item, index) => {
      const isActive = currentStep === item.id

      const percent = currentStep > item.id ? 100 : isActive ? currentCount : 0
      return (
        <div
          className={classNames('tab-item', { active: isActive })}
          key={index}
          onClick={() => {
            setCurrentCount(0)
            setCurrentStep(item.id)
          }}
        >
          <div className="tab-icon">
            <img src={item.icon} alt={item.title} />
          </div>

          <div className="tab-content">
            <p className="tab-title">{item.title}</p>
            <p className="tab-description">{item.description}</p>
            <Progress
              className="step-progress"
              percent={percent}
              showInfo={false}
            />
          </div>
        </div>
      )
    })
  }

  const handleChangeTab = (value: string) => {
    setCurrentTab(value)
    setCurrentCount(0)
    setCurrentStep(1)
  }

  return (
    <section className="step-by-steps face-recognition" id="step-by-steps">
      <div className="main-container custom-container">
        <h2 className="title">{t('How it work')}</h2>

        <div className="wrapper-tabs">
          <p
            className={classNames('tab-item', {
              active: currentTab === FEATURE_TABS.ADD_NEW,
            })}
            onClick={() => handleChangeTab(FEATURE_TABS.ADD_NEW)}
          >
            {t('Add new data')}
          </p>
          <p
            className={classNames('tab-item', {
              active: currentTab === FEATURE_TABS.FACE_RECOGNITION,
            })}
            onClick={() => handleChangeTab(FEATURE_TABS.FACE_RECOGNITION)}
          >
            {t('Face Recognition')}
          </p>
        </div>

        <div className="wrapper-steps">
          <div className="list-steps">
            {currentTab === FEATURE_TABS.ADD_NEW
              ? renderListTabs(listAddNewTabs)
              : renderListTabs(listFaceRecognitionTabs)}
          </div>
          <div className="current-step-content">
            <div className="image-step">
              <img
                src={
                  currentTab === FEATURE_TABS.ADD_NEW
                    ? listAddNewTabs[currentStep - 1].image
                    : listFaceRecognitionTabs[currentStep - 1].image
                }
                alt={
                  currentTab === FEATURE_TABS.ADD_NEW
                    ? listAddNewTabs[currentStep - 1].title
                    : listFaceRecognitionTabs[currentStep - 1].title
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StepBySteps
