import './style.scss'

type data = {
  title: string
  description: string
  image?: string
}

type PRServicesProps = {
  title: string
  description?: string
  image?: string
  data?: data[]
}

const PRServices: React.FC<PRServicesProps> = ({
  title,
  description,
  image,
  data,
}) => {
  const renderListItems = Array.isArray(data)
    ? data.map((item, index) => {
        return (
          <div className="wrapper-services-content" key={index}>
            <div className="services-content">
              <p className="services-title">{item.title}</p>
              <p className="services-description">{item.description}</p>
            </div>

            {item.image && (
              <div className="services-image">
                <img src={item.image} alt={item.title} />
              </div>
            )}
          </div>
        )
      })
    : []

  return (
    <section className="pr-services" id="pr-services">
      <div className="sub-background" />
      <div className="main-container custom-container">
        <div className="content">
          <h2 className="title">{title}</h2>
          {description && <p className="description">{description}</p>}
          {image && (
            <div className="image-box">
              <img src={image} alt={title} />
            </div>
          )}
        </div>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default PRServices
