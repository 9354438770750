import './style.scss'

import classNames from 'classnames'
import React from 'react'

interface CardSolutionProps {
  className?: string;
  image: string;
  title: string;
  description: string;
  game?: boolean;
}

const CardSolution: React.FC<CardSolutionProps> = ({
  className,
  image,
  title,
  description,
  game,
}) => {
  return (
    <div className={classNames('card-solution-item', { game }, className)}>
      <img src={image} alt={title} className="card-solution-image" />
      <div className="card-solution-title">{title}</div>
      <div className="card-solution-description">{description}</div>
    </div>
  )
}

export default CardSolution
