import * as Yup from 'yup'

// import { emailRegExp, phoneRegExp, positiveInteger } from '@/contants'

export const validationSchema = (t: any) => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .max(
        255,
        t('Full Name must be 255 characters or less.', { ns: 'validation' })
      )
      .required(t('Full Name is required.', { ns: 'validation' })),
    // country: Yup.string()
    //   .trim()
    //   .max(
    //     56,
    //     t('Country must be 56 characters or less.', { ns: 'validation' })
    //   )
    //   .required(t('Country is required.', { ns: 'validation' })),
    // birthday: Yup.string()
    //   .trim()
    //   .required(t('Birthday is required.', { ns: 'validation' })),
    gender: Yup.string()
      .trim()
      // .max(10, t('Age must be 10 characters or less.', { ns: 'validation' }))
      .required(t('Gender is required.', { ns: 'validation' })),
    // age: Yup.string()
    //   .trim()
    //   .matches(
    //     positiveInteger,
    //     t('Please enter a valid age.', { ns: 'validation' })
    //   )
    //   .max(3, t('Age must be 3 characters or less.', { ns: 'validation' }))
    //   .required(t('Age is required.', { ns: 'validation' })),
    // email: Yup.string()
    //   .trim()
    //   .max(
    //     255,
    //     t('Email must be 255 characters or less.', { ns: 'validation' })
    //   )
    //   .matches(
    //     emailRegExp,
    //     t('Please enter a valid email address.', { ns: 'validation' })
    //   )
    //   .email(t('Please enter a valid email address.', { ns: 'validation' }))
    //   .required(t('Email is required.', { ns: 'validation' })),
    // phoneNumber: Yup.string()
    //   .trim()
    //   .max(
    //     15,
    //     t('Phone number must be 15 characters or less.', { ns: 'validation' })
    //   )
    //   .matches(
    //     phoneRegExp,
    //     t('Please enter a valid phone number.', { ns: 'validation' })
    //   )
    //   .required(t('Phone Number is required.', { ns: 'validation' })),
  })
}
