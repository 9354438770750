import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { postAudioFile } from './speechTotext.api'

export const postAudioFileAction = createAsyncThunk<
  any,
  SpeechToText.FileToTextPayload
>('contact/postAudioFile', async ({ params }, { rejectWithValue }) => {
  try {
    return await postAudioFile(params)
  } catch (error: any) {
    if (error.error) {
      notification.error({
        message: i18next.t(`${error.error}`, { ns: 'errors' }),
        placement: 'bottomRight',
      })
    }

    return rejectWithValue(error?.error || error)
  }
})
