import './style.scss'

import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import Notification from '@/components/Common/Item/Notification'
import Spinner from '@/components/Common/Item/Spinner'
import { getInfoAuthenticateAction } from '@/states/features/thirdParty/thirdParty.action'
import { useAppDispatch, useAppSelector } from '@/states/store'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.thirdParty)

  const handleTryDemo = async () => {
    const newWindow = window.open('/popup-loading.html', '_blank')

    if (!newWindow) {
      Notification.error(
        t(
          'Cannot open new window. Browsers can block pop-ups. Please try again.'
        )
      )
      return
    }

    localStorage.setItem('loadingMessage', t('Loading, please wait...'))
    localStorage.setItem(
      'errorMessage',
      t('An error occurred, please try again later.')
    )
    localStorage.setItem('successMessage', t('Success!'))

    try {
      const result: any = await dispatch(getInfoAuthenticateAction({}))
      const sessionId = result?.payload?.data?.sessionCookie?.sessionId

      if (sessionId) {
        const url = `${process.env.REACT_APP_URL_DIGITAL_TRANSFORMATION}/auto-login?session_id=${sessionId}`
        newWindow.location.href = url
      } else {
        newWindow.close()
        Notification.error(t('Session ID does not exist!'))
        newWindow.document.body.innerHTML = `
        <div style="text-align: center; margin-top: 50px;">
          <p>${localStorage.getItem('errorMessage')}</p>
        </div>
      `
      }
    } catch (error) {
      newWindow.document.body.innerHTML = `
      <div style="text-align: center; margin-top: 50px; color: red;">
        <p>${localStorage.getItem('errorMessage')}</p>
      </div>
    `
      Notification.error(
        t('Error getting authentication information:', { error })
      )
    }
  }

  return (
    <section id="banner">
      <Spinner loading={isLoading} />
      <div className="banner digital-transformation">
        <div className="content-container">
          <div className="content">
            <p className="title">
              {t(
                'Transform Your Business with Comprehensive Digital Transformation'
              )}
            </p>
            <p className="description">
              {t('digital_transformation_description_banner')}
            </p>
            <Button
              className="explore_more w-[160px] h-[48px]"
              disabled={isLoading}
              onClick={handleTryDemo}
            >
              {t('Try Demo')}
            </Button>
          </div>
          <div className="bg-digital_transformation bg-contain bg-no-repeat w-[500px] h-[350px] max-w-[100%]"></div>
        </div>
      </div>
    </section>
  )
}

export default Banner
