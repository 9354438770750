import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import AboutUs from '../AboutUs'
import AISection from './AISection'
import Banner from './Banner'
import Features from './Features'

const AISolutions: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <AISection />
      <Features />
      <AboutUs className="mb-[60px]" />
    </Layout>
  )
}

export default AISolutions
