import './style.scss'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import classNames from 'classnames'
import React, { useState } from 'react'

const { Option } = Select

interface OptionType {
  value: string
  label: string
}

interface CustomSelectProps extends SelectProps<string> {
  label?: string
  className?: string
  error?: string | null
  required?: boolean
  inline?: boolean
  options: OptionType[]
  placeholder?: string
  onChange: (value: string) => void
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  className,
  label,
  error,
  required = false,
  inline = false,
  options,
  placeholder,
  onChange,
  ...restProps
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  )

  const handleChange = (value: string) => {
    setSelectedValue(value)
    onChange(value)
  }

  return (
    <div className={classNames('default-select', { inline, error }, className)}>
      {label && (
        <label className="label">
          {required && !inline && <span className="required">*</span>}
          {label}
          {required && inline && <span className="required">*</span>}
        </label>
      )}

      <Select
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
        {...restProps}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>

      {error && <p className="error-text">{error}</p>}
    </div>
  )
}

export default CustomSelect
