import i18n from '@/libs/i18n'
import Axios from '@/services/axios'

export async function faceRecognition(params: FaceRecognition.FileSend) {
  const formData = new FormData()
  formData.append('file', params.file)

  return await Axios.post('/face-recognition/recognition', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': i18n.language,
    },
  })
}

export async function identificationInfo(params: FaceRecognition.FileInfoSend) {
  const formData = new FormData()
  formData.append('file', params.file)
  formData.append('country', params.country)
  formData.append('fullName', params.fullName)
  formData.append('birthday', params.birthday)
  formData.append('gender', params.gender)
  formData.append('age', params.age)
  formData.append('email', params.email)
  formData.append('phoneNumber', params.phoneNumber)

  return await Axios.post('/face-recognition/identification-info', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': i18n.language,
    },
  })
}
