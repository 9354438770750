import './style.scss'

import { Button, ButtonProps } from 'antd'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

interface CustomButtonProps extends ButtonProps {
  children?: ReactNode | string;
  className?: string;
  radius?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  className,
  children,
  radius,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={classNames('btn-default', { radius }, className)}
    >
      {children}
    </Button>
  )
}

export default CustomButton
