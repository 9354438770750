import './style.scss'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageFeatures1 from '@/assets/img/aiSolutions/feature_1.png'
import ImageFeatures2 from '@/assets/img/aiSolutions/feature_2.png'
import ImageFeatures3 from '@/assets/img/aiSolutions/feature_3.png'
import ImageFeatures4 from '@/assets/img/aiSolutions/feature_4.png'
import ImageFeatures5 from '@/assets/img/aiSolutions/feature_5.png'
import ImageFeatures6 from '@/assets/img/aiSolutions/feature_6.png'
import ImageFeatures7 from '@/assets/img/aiSolutions/feature_7.png'
import Button from '@/components/Common/Item/Button'

const Features = () => {
  const { t, i18n } = useTranslation(['common'])
  const navigate = useNavigate()

  const listItems = [
    {
      image: ImageFeatures1,
      title: t('ai_solutions_features_title_1'),
      description: t('ai_solutions_features_description_1'),
      other: [
        {
          label: t('Applications'),
          value: t('Interviews, meeting minutes, customer service.'),
        },
        {
          label: t('Key Features'),
          value: t(
            'Multilingual support, real-time processing, high accuracy.'
          ),
        },
      ],
      textButton: t('Learn more'),
      path: '/speech-to-text',
    },
    {
      image: ImageFeatures2,
      title: t('ai_solutions_features_title_2'),
      description: t('ai_solutions_features_description_2'),
      other: [
        {
          label: t('Applications'),
          value: t(
            'Document digitization, invoice processing, extracting data from images.'
          ),
        },
        {
          label: t('Key Features'),
          value: t('Accurate text recognition, supports various file formats.'),
        },
      ],
      textButton: t('Digitize Your Documents Now'),
      path: '/ocr',
    },
    {
      image: ImageFeatures3,
      title: t('ai_solutions_features_title_3'),
      description: t('ai_solutions_features_description_3'),
      other: [
        {
          label: t('Applications'),
          value: t('Security, surveillance, user authentication.'),
        },
        {
          label: t('Key Features'),
          value: t(
            'Accurate face analysis, deep learning capabilities for ongoing improvement.'
          ),
        },
      ],
      textButton: t('Discover Facial Recognition Technology'),
      path: '/face-recognition',
    },
    {
      image: ImageFeatures4,
      title: t('ai_solutions_features_title_4'),
      description: t('ai_solutions_features_description_4'),
      other: [
        {
          label: t('Applications'),
          value: t('Rapid AI development, no-code integrations'),
        },
        {
          label: t('Key Features'),
          value: t(
            'Drag-and-drop interface, easy customization. Call to Action'
          ),
        },
      ],
      textButton: t('Learn more'),
      path: '/chatbot-platform',
    },
    {
      image: ImageFeatures5,
      title: t('ai_solutions_features_title_5'),
      description: t('ai_solutions_features_description_5'),
      other: [
        {
          label: t('Applications'),
          value: t('Customer service, tech support, automated consultations.'),
        },
        {
          label: t('Key Features'),
          value: t('Natural language processing, easy system integration.'),
        },
      ],
      textButton: t('Learn more'),
      path: '/chatbot-template',
    },
    {
      image: ImageFeatures6,
      title: t('ai_solutions_features_title_6'),
      description: t('ai_solutions_features_description_6'),
      other: [
        {
          label: t('Applications'),
          value: t('Content creation, text data analysis, language research.'),
        },
        {
          label: t('Key Features'),
          value: t('High performance, deep learning capabilities.'),
        },
      ],
      textButton: t('Learn more'),
      path: '/large-language-model',
    },
    {
      image: ImageFeatures7,
      title: t('ai_solutions_features_title_7'),
      description: t('ai_solutions_features_description_7'),
      other: [
        {
          label: t('Applications'),
          value: t('Design, artistic creation, advertising.'),
        },
        {
          label: t('Key Features'),
          value: t('Text-to-image generation, customizable artistic styles.'),
        },
      ],
      textButton: t('Learn more'),
      path: '/image-generator',
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    const isHalf = index > 2

    const list = item.other.map((x, idx) => (
      <div className="other-item" key={idx}>
        <p className="label">{x.label}:</p>
        <p className="value">{x.value}</p>
      </div>
    ))
    return (
      <div
        className={classNames('wrapper-features-content', { half: isHalf })}
        key={index}
      >
        <div className="features-content">
          <p className="features-title">{item.title}</p>
          <p className="features-description">{item.description}</p>
          {list.length > 0 && <div className="other-list-items">{list}</div>}
          <Button
            className="feature-button min-w-[137px] h-[40px]"
            onClick={() => navigate(`/${i18n.language}${item.path}`)}
          >
            {item.textButton}
          </Button>
        </div>

        <div className="features-image">
          <img src={item.image} alt={item.title} />
        </div>
      </div>
    )
  })

  return (
    <section className="ai-solutions-features" id="ai-solutions-features">
      <div className="main-container custom-container">
        <h2 className="title">{t('All Features')}</h2>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default Features
