/* eslint-disable sonarjs/cognitive-complexity*/
import './style.scss'

import { Upload, UploadFile, UploadProps } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImageUploadDragger from '@/assets/img/UploadDragger.png'
import IconPdf from '@/assets/svg/icon-pdf.svg'
import IconClose from '@/assets/svg/icon-x.svg'
import Button from '@/components/Common/Item/Button'
import Notification from '@/components/Common/Item/Notification'
import { MAX_SIZE_OF_FILE_5MB, TYPE_FILE_IMAGE } from '@/contants/upload'
import { isValidImage } from '@/utils/file'

const { Dragger } = Upload

interface CustomUploadProps extends UploadProps {
  className?: string
  icon?: string
  title?: string
  accept?: string
  webcam?: boolean
  textWebcam?: string
  size?: number
  onChange?: (info?: any) => void
  onRemoveFile?: () => void
  onWebcamAction?: () => void
}

const UploadDragger: React.FC<CustomUploadProps> = ({
  className,
  icon,
  title,
  accept,
  webcam,
  textWebcam,
  size,
  onChange,
  onRemoveFile,
  onWebcamAction,
  ...props
}) => {
  const { t } = useTranslation(['common', 'validation'])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [base64Images, setBase64Images] = useState<Record<string, string>>({})

  const maxSizeMB = size || MAX_SIZE_OF_FILE_5MB

  const customItemRender = (originNode: React.ReactNode, file: UploadFile) => {
    const isImage = TYPE_FILE_IMAGE.includes(file.type || '')
    const imageUrl = base64Images[file.uid]

    const image = isImage ? imageUrl : IconPdf
    return (
      <div className="list-item-upload">
        <p className="upload-title">{t('Upload')}</p>
        <div className="list-files">
          <div className="file-item">
            <img src={image} alt={file.name} />

            <span className="file-name">{file.name}</span>
            <img
              src={IconClose}
              alt="remove file"
              className="btn-remove-file"
              onClick={() => {
                setFileList([])
                if (onRemoveFile) return onRemoveFile()
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const getBase64 = (file: File, callback: (imageUrl: string) => void) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => callback(reader.result as string)
  }

  const handleChange = async (info: any) => {
    let newFileList = [...info.fileList]

    const fileSize = info.file.size || 0
    const isMaxSize = fileSize <= maxSizeMB * 1048576
    const fileExtension = (info.file.name.split('.').pop() || '').toLowerCase()
    const validExtensions = ['png', 'jpeg', 'jpg', 'pdf']
    const isValidType = (accept?.split(',') || []).includes(
      info.file.type || ''
    )

    const isValidFileImage = TYPE_FILE_IMAGE.includes(info.file.type)
      ? await isValidImage(info.file)
      : true

    if (newFileList.length > 1) {
      Notification.error(
        t('The file has been selected, please check again!', {
          ns: 'validation',
        })
      )
      return
    }

    if (!isMaxSize) {
      Notification.error(
        t('File must smaller than 5MB!', { ns: 'validation', size: maxSizeMB })
      )
      return
    }

    if (
      !isValidFileImage ||
      !isValidType ||
      !validExtensions.includes(fileExtension)
    ) {
      Notification.error(t('Invalid data type!', { ns: 'validation' }))
      return
    }

    newFileList.forEach((file) => {
      if (file.originFileObj && !base64Images[file.uid]) {
        getBase64(file.originFileObj, (imageUrl: string) => {
          setBase64Images((prev) => ({ ...prev, [file.uid]: imageUrl }))
        })
      }
    })

    // Keep only the most recent 5 files in the list
    newFileList = newFileList.slice(-5)
    setFileList(newFileList)

    if (info.file.status === 'done') {
      Notification.success(`${info.file.name} uploaded successfully`)
    } else if (info.file.status === 'error') {
      Notification.error(`${info.file.name} upload failed.`)
    }

    if (onChange) {
      return onChange(info)
    }
  }

  return (
    <Dragger
      {...props}
      accept={accept}
      beforeUpload={() => false}
      className={classNames('dragger-upload-default', className)}
      fileList={fileList}
      itemRender={customItemRender}
      onChange={handleChange}
      disabled={fileList.length > 0}
    >
      <div className="icon-upload">
        <img src={icon || ImageUploadDragger} alt="Upload dragger" />
      </div>
      <p className="ant-upload-text">
        {title || t('Drag and drop files here to upload')}
      </p>
      <p className="ant-upload-hint">
        {t('Upload unlimited files, 5MB total limit.', { size: maxSizeMB })}
      </p>

      {webcam ? (
        <>
          <p className="ant-upload-hint or-webcam">{textWebcam}</p>
          <Button
            radius
            className="w-[160px] h-[36px]"
            disabled={fileList.length > 0}
            onClick={(e) => {
              e.stopPropagation()
              if (onWebcamAction) onWebcamAction()
            }}
          >
            {t('Open Webcam')}
          </Button>
        </>
      ) : (
        <>
          <p className="ant-upload-hint or">{t('Or')}</p>
          <Button
            radius
            className="w-[125px] h-[36px]"
            disabled={fileList.length > 0}
          >
            {t('Select file')}
          </Button>
        </>
      )}
    </Dragger>
  )
}

export default UploadDragger
