import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { ocrFileToText } from './ocr.api'

export const ocrFileToTextAction = createAsyncThunk<any, OCR.FileToTextPayload>(
  'contact/ocrFileToText',
  async ({ params }, { rejectWithValue }) => {
    try {
      return await ocrFileToText(params)
    } catch (error: any) {
      if (error.error) {
        notification.error({
          message: i18next.t(`${error.error}`, { ns: 'errors' }),
          placement: 'bottomRight',
        })
      }

      return rejectWithValue(error?.error || error)
    }
  }
)
