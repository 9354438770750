import { createSlice } from '@reduxjs/toolkit'

import {
  getCaseStudiesPostDetailAction,
  getCaseStudiesPostsAction,
} from './caseStudies.action'

const initialState: CaseStudies.CaseStudiesState = {
  isLoading: false,
  errorCaseStudies: null,
  caseStudiesPosts: null,
}

export const { actions, reducer } = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.errorCaseStudies = null
    },
    resetCaseStudies: (state) => {
      state.caseStudiesPosts = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCaseStudiesPostsAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCaseStudiesPostsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.caseStudiesPosts = action.payload?.data
      })
      .addCase(getCaseStudiesPostsAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorCaseStudies = action.payload
      })
      .addCase(getCaseStudiesPostDetailAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCaseStudiesPostDetailAction.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getCaseStudiesPostDetailAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorCaseStudies = action.payload
      })
  },
})

export default actions
