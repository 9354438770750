import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageOffline from '@/assets/img/offline/image.png'
import Button from '@/components/Common/Item/Button'
import Notification from '@/components/Common/Item/Notification'
import useOfflineRedirect from '@/hooks/useOfflineRedirect'

const Offline: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const isOnline = useOfflineRedirect()

  const handleRefresh = () => {
    if (isOnline) {
      navigate(0)
    } else {
      Notification.error(t('Connection failed. Please try again.'))
    }
  }

  return (
    <div className={classNames('offline', { active: isActive })}>
      <div className="offline-content">
        <h2 className="offline-title">{t('Lost Connection')}</h2>
        <h2 className="offline-description">
          {t(
            'No internet connection was found. Check your connection or try again'
          )}
        </h2>

        <Button className="h-[48px] w-[244px]" onClick={handleRefresh}>
          {t('Retry')}
        </Button>
      </div>

      <div className="offline-image">
        <img src={ImageOffline} alt="offline" />
      </div>
    </div>
  )
}

export default Offline
