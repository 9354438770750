import i18n from '@/libs/i18n'
import Axios from '@/services/axios'

export async function ocrFileToText(params: OCR.FileToTextSend) {
  const formData = new FormData()
  formData.append('file', params.file)

  return await Axios.post('/file-to-text', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': i18n.language,
    },
  })
}
