import { createSlice } from '@reduxjs/toolkit'

import {
  getCategoriesPostsAction,
  getCategoriesStatisticsAction,
} from './categories.action'

const initialState: Categories.CategoriesState = {
  isLoading: false,
  errorCategories: null,
  categories: null,
  categoriesStatistics: null,
}

export const { actions, reducer } = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.errorCategories = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesPostsAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCategoriesPostsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.categories = action.payload.data?.records
      })
      .addCase(getCategoriesPostsAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorCategories = action.payload
      })

      .addCase(getCategoriesStatisticsAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCategoriesStatisticsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.categoriesStatistics = action.payload.data
      })
      .addCase(getCategoriesStatisticsAction.rejected, (state, action) => {
        state.isLoading = false
        state.errorCategories = action.payload
      })
  },
})

export default actions
