import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { detailPage, listPage } from './page.api'

export const listPageAction = createAsyncThunk<any, Page.GetListPagesPayload>(
  'page/listPage',
  async ({ data }, { rejectWithValue }) => {
    try {
      return await listPage(data)
    } catch (error: any) {
      if (error.error) {
        notification.error({
          message: i18next.t(`${error.error}`, { ns: 'errors' }),
          placement: 'bottomRight',
        })
      }

      return rejectWithValue(error?.error || error)
    }
  }
)

export const detailPageAction = createAsyncThunk<
  any,
  Page.GetPageDetailPayload
>('page/detailPage', async ({ data }, { rejectWithValue }) => {
  try {
    return await detailPage(data)
  } catch (error: any) {
    if (error.error) {
      notification.error({
        message: i18next.t(`${error.error}`, { ns: 'errors' }),
        placement: 'bottomRight',
      })
    }

    if (error.error === 'SLUG_NOT_FOUND') {
      window.open('/not-found', '_self')
    }

    return rejectWithValue(error?.error || error)
  }
})
