import axios from 'axios'

export class AxiosClient {
  token = localStorage.getItem('ACCESS_TOKEN') ?? ''

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}api/v1/`,
      headers: {
        Authorization: this.getToken(),
      },
      timeout: Number(process.env.REACT_APP_TIME_OUT_API) * 1000 || 10000,
      timeoutErrorMessage: 'The Request Has Timed Out',
    })
    this._initializeResponseInterceptor()
  }

  getToken() {
    return `Bearer ${this.token.replaceAll('"', '')}`
  }

  setToken(token) {
    this.token = token
  }

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequestSuccess,
      this._handleRequestError
    )
    this.instance.interceptors.response.use(
      this._handleResponseSuccess,
      this._handleResponseError
    )
  }

  _handleRequestSuccess = (config) => {
    return config
  }

  _handleRequestError = (error) => {
    console.error(`[request error] [${JSON.stringify(error)}]`)

    if (error.response) {
      return error?.response?.data
    }

    return Promise.reject(error)
  }

  _handleResponseSuccess = ({ data }) => data

  _handleResponseError = async (error) => {
    if (error.code && error.code === 'ERR_NETWORK') {
      /*
        code: "ERR_NETWORK"
        message: "Network Error"
        name: "AxiosError"
      */
      const { code } = error
      return await Promise.reject(new Error(code))
    }

    return await Promise.reject({
      httpStatus: error.response?.status,
      ...(error?.response?.data || {}),
    })
  }

  async request(config) {
    return await this.instance.request(config)
  }

  async get(url, config) {
    return await this.instance.get(url, config)
  }

  async delete(url, config) {
    return await this.instance.delete(url, config)
  }

  async post(url, data, config) {
    return await this.instance.post(url, data, config)
  }

  async put(url, data, config) {
    return await this.instance.put(url, data, config)
  }

  async patch(url, data, config) {
    return await this.instance.patch(url, data, config)
  }
}
