/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */

//constant variable.
export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

export const emailRegExp =
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

export const positiveInteger = /^[1-9][0-9]*$/

export const AUDIO_MAX_RECORD_TIME =
  Number(process.env.REACT_APP_AUDIO_MAX_RECORD_TIME) || 300

export const COMPONENT_KEYS = {
  BANNER: 'BANNER',
  SERVICE: 'SERVICE',
  FEATURE: 'FEATURE',
  TECHNOLOGY: 'TECHNOLOGY',
  FAQ: 'FAQ',
}

export const CATEGORY_KEYS = {
  AI_PACKAGES: 'AI Packages',
  DIGITAL_TRANSFORMATION: 'Digital Transformation',
  BLOCKCHAIN: 'Blockchain',
  GAME_METAVERSE: 'Game & Metaverse',
  OTHERS: 'Others',
}

export const LIST_LANGUAGE = ['en', 'ko', 'ja', 'vi']
