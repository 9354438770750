import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import AboutUs from '../AboutUs'
import Banner from './Banner'
import CreateGame from './CreateGame'
import Introduce from './Introduce'
import Technologies from './Technologies'

const GameMetaverse: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <Introduce />
      <Technologies />
      <CreateGame />
      <AboutUs className="mb-[60px]" />
    </Layout>
  )
}

export default GameMetaverse
