import './style.scss'

import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/Common/Item/Button'

type data = {
  title: string
  image?: string
  path: string
}

type HomeListCardsProps = {
  title: string
  description?: string
  image?: string
  data: data[]
}

const HomeListCards: React.FC<HomeListCardsProps> = ({
  title,
  description,
  image,
  data,
}) => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const [list, setList] = useState<data[]>([])

  const pageSize = 8

  useEffect(() => {
    if (data.length > pageSize) {
      const newList = data.slice(0, pageSize)
      setList(newList)
    } else {
      setList(data)
    }
  }, [data])

  const renderListItems = list.map((item, index) => (
    <Col key={index} xs={24} sm={12} md={6}>
      <div className="card-item" onClick={() => navigate(item.path)}>
        <img src={item.image} alt={item.title} />

        <div className="content-item">
          <p className="title-item">{item.title}</p>
        </div>
      </div>
    </Col>
  ))

  return (
    <section className="home-list-cards">
      <div className="main-container custom-container">
        <h2 className="title">{title}</h2>

        {description && <p className="description">{description}</p>}

        {image && (
          <div className="image-box">
            <img src={image} alt={title} />
          </div>
        )}

        <div className="list-content-items">
          <Row
            gutter={[16, 16]}
            className="m-w-full"
            justify="center"
            align="stretch"
          >
            {renderListItems}
          </Row>
        </div>

        {data.length > pageSize && renderListItems.length !== data.length && (
          <div className="box-actions">
            <Button
              className="read-more w-[160px] h-[48px]"
              onClick={() => setList(data.slice(0, list.length + pageSize))}
            >
              {t('See all')}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}

export default HomeListCards
