import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useGetTextLanguage = (
  fieldName: 'title' | 'description',
  data?: TemplateDataPR.SectionInfo
): string => {
  const { i18n } = useTranslation()
  const [text, setText] = useState('')

  useEffect(() => {
    if (data && fieldName) {
      getText(fieldName, data)
    }
  }, [i18n.language])

  const getText = useCallback(
    (fieldName: 'title' | 'description', data: TemplateDataPR.SectionInfo) => {
      switch (i18n.language) {
        case 'en':
          setText(data[`${fieldName}En`] as string)
          return
        case 'ja':
          setText(data[`${fieldName}Ja`] as string)
          return
        case 'ko':
          setText(data[`${fieldName}Ko`] as string)
          return
        case 'vi':
          setText(data[`${fieldName}Vi`] as string)
          return
        default:
          return ''
      }
    },
    [i18n.language]
  )

  return !data || !fieldName ? '' : text
}

export default useGetTextLanguage
