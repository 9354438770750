import './style.scss'

import { Input, InputProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface CustomInputProps extends InputProps {
  label?: string
  className?: string
  error?: string | null
  required?: boolean
  inline?: boolean
  result?: boolean
}

const CustomInput: React.FC<CustomInputProps> = ({
  className,
  label,
  error,
  required = false,
  inline = false,
  result = false,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'default-input',
        { inline, error, result },
        className
      )}
    >
      {label && (
        <label className="label">
          {required && !inline && !result && (
            <span className="required">*</span>
          )}
          {label}
          {required && inline && !result && <span className="required">*</span>}
        </label>
      )}

      <Input {...props} />

      {error && <p className="error-text">{error}</p>}
    </div>
  )
}

export default CustomInput
