import './style.scss'

import { useTranslation } from 'react-i18next'

import IconTech1 from '@/assets/img/digitalTransformation/tech_1.svg'
import IconTech2 from '@/assets/img/digitalTransformation/tech_2.svg'
import IconTech3 from '@/assets/img/digitalTransformation/tech_3.svg'
import IconTech4 from '@/assets/img/digitalTransformation/tech_4.svg'
import IconTech5 from '@/assets/img/digitalTransformation/tech_5.svg'
import IconTech6 from '@/assets/img/digitalTransformation/tech_6.svg'
import IconTech7 from '@/assets/img/digitalTransformation/tech_7.svg'
import IconTech8 from '@/assets/img/digitalTransformation/tech_8.svg'
import ImageTechnologies from '@/assets/img/digitalTransformation/technologies.png'

const Technologies = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      icon: IconTech1,
      label: t('Cloud Computing'),
    },
    {
      icon: IconTech2,
      label: t('Artificial Intelligence (AI)'),
    },
    {
      icon: IconTech3,
      label: t('Internet of Things (IoT)'),
    },
    {
      icon: IconTech4,
      label: t('Big Data & Analytics'),
    },
    {
      icon: IconTech5,
      label: t('Automation'),
    },
    {
      icon: IconTech6,
      label: t('Blockchain'),
    },
    {
      icon: IconTech7,
      label: t('Cybersecurity Solutions'),
    },
    {
      icon: IconTech8,
      label: t('Augmented Reality (AR)'),
    },
  ]

  const listContents = [
    {
      title: t('technology_title_1'),
      description: t('technology_description_1'),
    },
    {
      title: t('technology_title_2'),
      description: t('technology_description_2'),
    },
    {
      title: t('technology_title_3'),
      description: t('technology_description_3'),
    },
    {
      title: t('technology_title_4'),
      description: t('technology_description_4'),
    },
    {
      title: t('technology_title_5'),
      description: t('technology_description_5'),
    },
    {
      title: t('technology_title_6'),
      description: t('technology_description_6'),
    },
    {
      title: t('technology_title_7'),
      description: t('technology_description_7'),
    },
    {
      title: t('technology_title_8'),
      description: t('technology_description_8'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="card-tech-item" key={index}>
        <img src={item.icon} alt={item.label} />
        <p className="card-label">{item.label}</p>
      </div>
    )
  })

  const renderListContents = listContents.map((item, index) => {
    return (
      <div className="content-item" key={index}>
        <p className="content-title">{item.title}</p>
        <p className="content-description">{item.description}</p>
      </div>
    )
  })

  return (
    <section
      className="digital_transformation_technologies"
      id="digital_transformation_technologies"
    >
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('Digital transformation technologies')}</h2>
          <div className="list-tech-cards">{renderListItems}</div>
          <div className="image-technologies">
            <img
              src={ImageTechnologies}
              alt="Digital transformation technologies"
            />
          </div>
        </div>

        <div className="list-contents">{renderListContents}</div>
      </div>
    </section>
  )
}

export default Technologies
