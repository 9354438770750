import './style.scss'

import { useTranslation } from 'react-i18next'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const Clients = () => {
  const { t } = useTranslation(['common'])

  const screenWidth = document.body.clientWidth
  const clientLogoWidth = 190
  const countClientLogoItems = Math.floor(screenWidth / clientLogoWidth)

  const images = [
    'img/logoClients/image_1.png',
    'img/logoClients/image_2.png',
    'img/logoClients/image_3.png',
    'img/logoClients/image_4.png',
    'img/logoClients/image_5.png',
    'img/logoClients/image_6.png',
    'img/logoClients/image_7.png',
    'img/logoClients/image_8.png',
    'img/logoClients/image_9.png',
    'img/logoClients/image_10.png',
    'img/logoClients/image_11.png',
    'img/logoClients/image_12.png',
    'img/logoClients/image_13.png',
    'img/logoClients/image_14.png',
    'img/logoClients/image_15.png',
    'img/logoClients/image_16.png',
    'img/logoClients/image_17.png',
    'img/logoClients/image_18.png',
    'img/logoClients/image_19.png',
    'img/logoClients/image_20.png',
    'img/logoClients/image_21.png',
    'img/logoClients/image_22.png',
  ]

  const renderListImage = images.map((item, index) => (
    <div key={index} className="company-logo">
      <img src={item} alt="company-logo" />
    </div>
  ))

  return (
    <section className="clients" id="clients">
      <div className="box-title">
        <h2 className="title">{t('marketing:clients_title')}</h2>
      </div>
      <p className="description">{t('marketing:clients_description')}</p>

      <div className="wrapper-slider">
        <Swiper
          slidesPerView={countClientLogoItems}
          spaceBetween={10}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            600: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: countClientLogoItems,
              slidesPerGroup: 1,
            },
          }}
          modules={[Autoplay]}
          loop={true}
          speed={500}
        >
          {renderListImage.map((image, index) => (
            <SwiperSlide key={index}>{image}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Clients
