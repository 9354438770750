import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { getCategoriesPosts, getCategoriesStatistics } from './categories.api'

export const getCategoriesPostsAction = createAsyncThunk<
  any,
  Categories.CategoriesPayload
>('contact/getCategoriesPosts', async ({ params }, { rejectWithValue }) => {
  try {
    return await getCategoriesPosts(params)
  } catch (error: any) {
    if (error.error) {
      notification.error({
        message: i18next.t(`${error.error}`, { ns: 'errors' }),
        placement: 'bottomRight',
      })
    }

    return rejectWithValue(error?.error || error)
  }
})

export const getCategoriesStatisticsAction = createAsyncThunk<any>(
  'contact/getCategoriesStatistics',
  async (_, { rejectWithValue }) => {
    try {
      return await getCategoriesStatistics()
    } catch (error: any) {
      if (error.error) {
        notification.error({
          message: i18next.t(`${error.error}`, { ns: 'errors' }),
          placement: 'bottomRight',
        })
      }

      return rejectWithValue(error?.error || error)
    }
  }
)
