import './style.scss'

import { Modal } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
interface CustomModalProps {
  className?: string
  title: string
  content: string
  visible: boolean
  onClose: () => void
  onOk: () => void
}

const CustomModal: React.FC<CustomModalProps> = ({
  className,
  title,
  content,
  visible,
  onClose,
  onOk,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <Modal
      centered
      className={classNames('modal-default', className)}
      open={visible}
      closable={false}
      footer={null}
    >
      <div className="wrapper-content-modal">
        <p className="title">{title}</p>
        <p className="content">{content}</p>
      </div>

      <div className="box-actions">
        <Button
          className="min-w-[79px] h-[36px] basic"
          radius
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button className="min-w-[53px] h-[36px]" radius onClick={onOk}>
          {t('OK')}
        </Button>
      </div>
    </Modal>
  )
}

export default CustomModal
