import { createSlice } from '@reduxjs/toolkit'

import { detailPageAction, listPageAction } from './page.action'

const initialState: Page.PageState = {
  isLoading: false,
  initPage: false,
  listPage: {
    limit: 0,
    page: 0,
    records: [],
    total: 0,
    totalPage: 0,
  },
  detailPage: null,
  error: false,
}

export const { actions, reducer } = createSlice({
  name: 'Page',
  initialState,
  reducers: {
    resetErrorContact: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPageAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(listPageAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.initPage = true
        state.listPage = action.payload.data
      })
      .addCase(listPageAction.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(detailPageAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(detailPageAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.detailPage = action.payload.data
      })
      .addCase(detailPageAction.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  },
})

export default actions
