// eslint-disable-next-line simple-import-sort/imports
import { Suspense, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

// import Blog from '@/components/Common/Pages/Blog'

// import BlogDetail from '@/components/Common/Pages/BlogDetail'
// import ProjectDetail from '@/components/Common/Pages/ProjectDetail'
// import WhisperV3 from '@/components/Common/Pages/ProjectDetail/WhisperV3/WhisperV3'
// import OCRProductDetail from '@/components/Common/Pages/ProjectDetail/OCR'
// import BKYC from '@/components/Common/Pages/ProjectDetail/BKYC'
// import BEMO from '@/components/Common/Pages/ProjectDetail/Bemo'
import Top from '@/components/Common/Pages/Top'

import AISpeech2Text from '@/components/Common/Pages/AISpeech2Text'
import AIOcr from '@/components/Common/Pages/AIOcr'
import AIFaceRecognition from '@/components/Common/Pages/AIFaceRecognition'

// import DifyAI from '@/components/Common/Pages/DifyAI'
// import LibreChat from '@/components/Common/Pages/LibreChat'
// import Llama3 from '@/components/Common/Pages/Llama3'
// import StableDiffusion from '@/components/Common/Pages/StableDiffusion'
import AISolutions from '@/components/Common/Pages/AISolutions'
import DigitalTransformation from '@/components/Common/Pages/DigitalTransformation'
import Blockchain from '@/components/Common/Pages/Blockchain'
import GameMetaverse from '@/components/Common/Pages/GameMetaverse'
// import Metaverse from '@/components/Common/Pages/Metaverse'

// import MeraOffice from '@/components/Common/Pages/MeraOffice'
// import NftMarketplace from '@/components/Common/Pages/NftMarketplace'

import { useAppDispatch, useAppSelector } from '@/states/store'
import { listPageAction } from '@/states/features/page/page.action'
import Spinner from '../Common/Item/Spinner'
import PRPage from '@/templates/PRPage'

import NotFound from '@/components/Common/Pages/NotFound'
import i18n from '@/libs/i18n'
import { LIST_LANGUAGE } from '@/contants'
import { setIsMobile } from '@/states/app'

function PublicRoute() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { initPage, isLoading, listPage } = useAppSelector(
    (state) => state.page
  )

  const lang = location.pathname.slice(1, 3)

  useEffect(() => {
    const checkMobile = () => {
      dispatch(setIsMobile(window.innerWidth <= 768))
    }

    window.addEventListener('resize', checkMobile)
    checkMobile()

    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    if (lang && !LIST_LANGUAGE.includes(lang)) {
      navigate('not-found')
    } else if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang)
    }
  }, [lang])

  useEffect(() => {
    if (!initPage) {
      init()
    }
  }, [initPage])

  const init = async () => {
    await dispatch(
      listPageAction({
        data: {
          page: 1,
          limit: 100,
          sorts: 'created_at-desc',
        },
      })
    )
  }

  const listPagePR = Array.isArray(listPage?.records)
    ? listPage.records.map((page, index) => {
        return (
          <Route
            path={`/:lang/${page.slug}`}
            element={<PRPage />}
            key={index}
          />
        )
      })
    : []

  return (
    <Suspense fallback={null}>
      <Spinner loading={isLoading} />
      <Routes key={lang}>
        <Route path="/" element={<Navigate to="/en" />} />
        <Route element={<Top />} path="/:lang" />
        {/* <Route element={<Top />} path="/about-us" /> */}

        {/* <Route element={<Blog />} path="/blog" /> */}
        {/* <Route element={<BlogDetail />} path="/blog/:blogSlug" /> */}
        {/* <Route element={<WhisperV3 />} path="/bap-whisper-large-v3" /> */}
        {/* <Route element={<OCRProductDetail />} path="/bap-ocr" /> */}
        {/* <Route element={<BKYC />} path="/BKYC" /> */}
        {/* <Route element={<BEMO />} path="/bemo-cloud" /> */}
        {/* <Route element={<ProjectDetail />} path="/product/:slug" /> */}

        <Route path="/:lang/speech-to-text" element={<AISpeech2Text />} />
        <Route path="/:lang/ocr" element={<AIOcr />} />
        <Route path="/:lang/face-recognition" element={<AIFaceRecognition />} />

        {/* <Route path="chatbot-platform" element={<DifyAI />} />
        <Route path="chatbot-template" element={<LibreChat />} />
        <Route path="large-language-model" element={<Llama3 />} />
        <Route path="image-generator" element={<StableDiffusion />} /> */}

        <Route path="/:lang/ai-products" element={<AISolutions />} />
        <Route
          path="/:lang/digital-transformation"
          element={<DigitalTransformation />}
        />
        <Route path="/:lang/blockchain" element={<Blockchain />} />
        {/* <Route path="nft-marketplace" element={<NftMarketplace />} /> */}

        <Route path="/:lang/game-metaverse" element={<GameMetaverse />} />
        {/* <Route path="mera-office" element={<MeraOffice />} />
        <Route path="metaverse" element={<Metaverse />} /> */}

        {listPagePR}

        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default PublicRoute
