import Axios from '@/services/axios'

const statusDefault = 'PUBLIC'

export async function getCaseStudiesPosts(
  params: CaseStudies.CaseStudiesPostsParams
) {
  return await Axios.get('/case-studies', {
    params: { ...params, status: statusDefault },
  })
}

export async function getCaseStudiesPostDetail(
  params: CaseStudies.CaseStudiesPostDetailParams
) {
  return await Axios.get(`/case-studies/${params.postId}`, { params })
}
