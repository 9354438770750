import './style.scss'

import classNames from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

import ImageHand from '@/assets/img/hand.png'
import Button from '@/components/Common/Item/Button'
import Input from '@/components/Common/Item/Input'
import TextArea from '@/components/Common/Item/TextArea'
import { sendContactAction } from '@/states/features/contact/contact.action'
import { useAppDispatch, useAppSelector } from '@/states/store'

import { contactFormSchema } from './ContactFormSchema'

const Contact = () => {
  const { t, i18n } = useTranslation(['common', 'validation'])
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector((state) => state.contact)
  const [captchaValue, setCaptchaValue] = useState<string | null>(null)

  useEffect(() => {
    formik.validateForm()
  }, [i18n.language])

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      companyName: '',
      phoneNumber: '',
      message: '',
    },
    validationSchema: contactFormSchema(t),
    onSubmit: async (values, { resetForm }) => {
      const params = {
        name: values.name.trim(),
        email: values.email.trim(),
        companyName: values.companyName.trim(),
        phoneNumber: values.phoneNumber.trim(),
        message: values.message.trim(),
      }
      const result = await dispatch(sendContactAction({ params }))

      if (result.payload.code === 200) {
        resetForm({})
      }
    },
  })

  return (
    <section className="contact" id="contact">
      <div className="main-container custom-container">
        <div className="main-image">
          <img src={ImageHand} alt="hand-sub" />
        </div>
        <div className="wrapper-contact">
          <h2 className="title">{t('marketing:contact_title')}</h2>
          <h3 className="sub-title">{t('marketing:contact_detail_title')}</h3>
          <p className="description">
            {t('marketing:contact_detail_description')}
          </p>

          <div className="contact-form-main">
            <form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    required
                    label={t('Full Name')}
                    placeholder={t('Full Name')}
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null
                    }
                  />
                </div>
                <div className="ip-part">
                  <Input
                    required
                    label={t('Email')}
                    placeholder={t('Email')}
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </div>
              </div>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    required
                    label={t('Company')}
                    placeholder={t('Company')}
                    name="companyName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    error={
                      formik.touched.companyName && formik.errors.companyName
                        ? formik.errors.companyName
                        : null
                    }
                  />
                </div>
                <div className="ip-part">
                  <Input
                    required
                    label={t('Phone Number')}
                    placeholder={t('Phone Number')}
                    name="phoneNumber"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  />
                </div>
              </div>
              <div className="ip-full">
                <TextArea
                  required
                  label={t('Message')}
                  placeholder={t('Message')}
                  name="message"
                  rows={5}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={
                    formik.touched.message && formik.errors.message
                      ? formik.errors.message
                      : null
                  }
                />
              </div>

              <div className="box-actions mt-[26px]">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_KEY_CAPTCHA || ''}
                  onChange={handleCaptchaChange}
                />

                <Button
                  className={classNames('btn-loading min-w-[240px] h-[60px]', {
                    load: isLoading,
                  })}
                  htmlType="submit"
                  disabled={isLoading || !captchaValue}
                >
                  <>
                    <span className="span-text">{t('Send')}</span>
                    <span className="loading-spinner"></span>
                  </>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
