import './style.scss'

import React from 'react'

import ImageLoading from '@/assets/img/spinner/loading.png'

interface Props {
  loading: boolean
}

const Loading: React.FC<Props> = ({ loading }) => {
  return (
    <div
      className={`wrapper-loading d-md-block d-none loading-circular ${
        loading ? 'active' : 'inactive'
      }`}
    >
      <img
        className="imageLoader1"
        width={50}
        height={50}
        alt="loading spinner"
        src={ImageLoading}
      />
    </div>
  )
}

export default Loading
