import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageSolution1 from '@/assets/img/gameMetaverse/solution_1.svg'
import ImageSolution2 from '@/assets/img/gameMetaverse/solution_2.svg'
import ImageSolution3 from '@/assets/img/gameMetaverse/solution_3.svg'
import ImageSolution4 from '@/assets/img/gameMetaverse/solution_4.svg'
import ImageSolution5 from '@/assets/img/gameMetaverse/solution_5.svg'
import CardSolution from '@/components/Common/Item/CardSolution'

const CreateGame = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageSolution1,
      title: t('game_metaverse_solution_title_1'),
      description: t('game_metaverse_solution_description_1'),
    },
    {
      image: ImageSolution2,
      title: t('game_metaverse_solution_title_2'),
      description: t('game_metaverse_solution_description_2'),
    },
    {
      image: ImageSolution3,
      title: t('game_metaverse_solution_title_3'),
      description: t('game_metaverse_solution_description_3'),
    },
    {
      image: ImageSolution4,
      title: t('game_metaverse_solution_title_4'),
      description: t('game_metaverse_solution_description_4'),
    },
    {
      image: ImageSolution5,
      title: t('game_metaverse_solution_title_5'),
      description: t('game_metaverse_solution_description_5'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <CardSolution
        key={index}
        image={item.image}
        title={item.title}
        description={item.description}
        game
      />
    )
  })

  return (
    <section className="game_metaverse_create" id="game_metaverse_create">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('How To Create a Metaverse Game?')}</h2>
          <p className="description">
            {t('game_metaverse_create_description')}
          </p>
        </div>

        <div className="list-cards">{renderListItems}</div>
      </div>
    </section>
  )
}

export default CreateGame
