const useScrollToSection = () => {
  const scrollToSection = (sectionId: string, offset = 0): void => {
    const section = document.getElementById(sectionId)

    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.scrollY + offset

      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth',
      })
    } else {
      window.location.href = '/' + sectionId
    }
  }

  return { scrollToSection }
}

export default useScrollToSection
