import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/ocr/banner-ocr.png'
import ImageFeature1 from '@/assets/img/ocr/feature_1.png'
import ImageFeature2 from '@/assets/img/ocr/feature_2.png'
import ImageFeature3 from '@/assets/img/ocr/feature_3.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

import Difference from './Difference'
import OcrDemoComponent from './OcrDemo'
import StepBySteps from './StepBySteps'

const AIOcr: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  const dataBanner = {
    titleVi: t('OCR Scanner'),
    titleEn: t('OCR Scanner'),
    titleJa: t('OCR Scanner'),
    titleKo: t('OCR Scanner'),
    descriptionVi: t('ocr_description'),
    descriptionEn: t('ocr_description'),
    descriptionJa: t('ocr_description'),
    descriptionKo: t('ocr_description'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('Cutting-Edge Technology Enhances Document Automation'),
    titleEn: t('Cutting-Edge Technology Enhances Document Automation'),
    titleJa: t('Cutting-Edge Technology Enhances Document Automation'),
    titleKo: t('Cutting-Edge Technology Enhances Document Automation'),
    items: [
      {
        titleVi: t('ocr_features_title_1'),
        titleEn: t('ocr_features_title_1'),
        titleJa: t('ocr_features_title_1'),
        titleKo: t('ocr_features_title_1'),
        descriptionVi: t('ocr_features_description_1'),
        descriptionEn: t('ocr_features_description_1'),
        descriptionJa: t('ocr_features_description_1'),
        descriptionKo: t('ocr_features_description_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('ocr_features_title_2'),
        titleEn: t('ocr_features_title_2'),
        titleJa: t('ocr_features_title_2'),
        titleKo: t('ocr_features_title_2'),
        descriptionVi: t('ocr_features_description_2'),
        descriptionEn: t('ocr_features_description_2'),
        descriptionJa: t('ocr_features_description_2'),
        descriptionKo: t('ocr_features_description_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('ocr_features_title_3'),
        titleEn: t('ocr_features_title_3'),
        titleJa: t('ocr_features_title_3'),
        titleKo: t('ocr_features_title_3'),
        descriptionVi: t('ocr_features_description_3'),
        descriptionEn: t('ocr_features_description_3'),
        descriptionJa: t('ocr_features_description_3'),
        descriptionKo: t('ocr_features_description_3'),
        image: ImageFeature3,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('Frequently Asked Questions about OCR'),
    titleEn: t('Frequently Asked Questions about OCR'),
    titleJa: t('Frequently Asked Questions about OCR'),
    titleKo: t('Frequently Asked Questions about OCR'),
    items: [
      {
        titleVi: t('faq_ocr_title_1'),
        titleEn: t('faq_ocr_title_1'),
        titleJa: t('faq_ocr_title_1'),
        titleKo: t('faq_ocr_title_1'),
        descriptionVi: t('faq_ocr_description_1'),
        descriptionEn: t('faq_ocr_description_1'),
        descriptionJa: t('faq_ocr_description_1'),
        descriptionKo: t('faq_ocr_description_1'),
      },
      {
        titleVi: t('faq_ocr_title_2'),
        titleEn: t('faq_ocr_title_2'),
        titleJa: t('faq_ocr_title_2'),
        titleKo: t('faq_ocr_title_2'),
        descriptionVi: t('faq_ocr_description_2'),
        descriptionEn: t('faq_ocr_description_2'),
        descriptionJa: t('faq_ocr_description_2'),
        descriptionKo: t('faq_ocr_description_2'),
      },
      {
        titleVi: t('faq_ocr_title_3'),
        titleEn: t('faq_ocr_title_3'),
        titleJa: t('faq_ocr_title_3'),
        titleKo: t('faq_ocr_title_3'),
        descriptionVi: t('faq_ocr_description_3'),
        descriptionEn: t('faq_ocr_description_3'),
        descriptionJa: t('faq_ocr_description_3'),
        descriptionKo: t('faq_ocr_description_3'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))
  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="explore_more min-w-[245px] h-[48px]"
            onClick={() => {
              scrollToSection('demo-ocr')
            }}
          >
            {t('Upload file')}
          </Button>
        }
      >
        <OcrDemoComponent />
      </Banner>
      <StepBySteps />
      <Difference />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default AIOcr
