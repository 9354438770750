import i18n from '@/libs/i18n'
import Axios from '@/services/axios'

export async function postAudioFile(params: SpeechToText.FileToTextSend) {
  const formData = new FormData()
  formData.append('file', params.file, '.webm')

  return await Axios.post('/speech-to-texts', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': i18n.language,
    },
  })
}
