import './style.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageMain from '@/assets/img/notFound/image.png'
import ImageLogo from '@/assets/img/notFound/Logo.png'
import Button from '@/components/Common/Item/Button'

const Offline: React.FC = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  return (
    <div className="not-found">
      <div className="content">
        <img src={ImageLogo} alt="Logo" className="logo" />

        <img src={ImageMain} alt="BAP Solution" />
        <h2 className="title">{t('Page not found')}</h2>

        <p className="description">
          {t(
            `The page you are looking for doesn't exist or an other error occurred.`
          )}
        </p>

        <Button className="h-[56px] w-[327px]" onClick={() => navigate('/')}>
          {t('Back to home page')}
        </Button>
      </div>
    </div>
  )
}

export default Offline
