import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import addMessage from './addMessage'
import app from './app'
import { reducer as caseStudiesReducer } from './features/caseStudies/caseStudies.reducer'
import { reducer as categoriesReducer } from './features/categories/categories.reducer'
import { reducer as contactReducer } from './features/contact/contact.reducer'
import { reducer as faceRecognitionReducer } from './features/faceRecognition/faceRecognition.reducer'
import { reducer as ocrReducer } from './features/ocr/ocr.reducer'
import { reducer as pageReducer } from './features/page/page.reducer'
import { reducer as speechToTextReducer } from './features/speechToText/speechToText.reducer'
import { reducer as thirdPartyReducer } from './features/thirdParty/thirdParty.reducer'

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, addMessage)

export const store = configureStore({
  reducer: {
    app,
    contact: contactReducer,
    caseStudies: caseStudiesReducer,
    ocr: ocrReducer,
    addMessage: persistedReducer,
    speechToText: speechToTextReducer,
    thirdParty: thirdPartyReducer,
    categories: categoriesReducer,
    faceRecognition: faceRecognitionReducer,
    page: pageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
