import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageAwards from '@/assets/img/top/award.png'
import ImageMain from '@/assets/img/top/container.png'
import Button from '@/components/Common/Item/Button'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'

const Awards = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  return (
    <section className="awards" id="awards">
      <div className="main-container custom-container">
        <div className="content">
          <h2 className="title">{t('marketing:awards_title')}</h2>
          <p className="description">{t('marketing:awards_description')}</p>

          <img src={ImageAwards} alt="awards" />
          <Button
            className="w-[160px] h-[48px]"
            onClick={() => scrollToSection('contact', -80)}
          >
            {t('Contact')}
          </Button>
        </div>

        <div className="main-image">
          <img src={ImageMain} alt="awards" />
        </div>
      </div>
    </section>
  )
}

export default Awards
