import './style.scss'

import { Switch, SwitchProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface CustomSwitchProps extends SwitchProps {
  label?: string;
  className?: string;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  label,
  className,
  ...props
}) => {
  return (
    <div className={classNames('custom-switch', className)}>
      {label && <span className="custom-switch-label">{label}</span>}
      <Switch {...props} />
    </div>
  )
}

export default CustomSwitch
