import './style.scss'

import { UploadFile } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import Loading from '@/components/Common/Item/Loading'
import PDFViewer from '@/components/Common/Item/PdfViewer'
import TextArea from '@/components/Common/Item/TextArea'
import UploadDragger from '@/components/Common/Item/UploadDragger'
import { getAccept, TYPE_FILE_IMAGE, TYPE_FILE_PDF } from '@/contants/upload'
import { ocrFileToTextAction } from '@/states/features/ocr/ocr.action'
import actions from '@/states/features/ocr/ocr.reducer'
import { useAppDispatch, useAppSelector } from '@/states/store'
import { handleDownload } from '@/utils/download'
import { convertImageToUrl, convertPdfToUrl } from '@/utils/file'

const { resetFileToText, setFileToText } = actions

const OcrDemo: React.FC = () => {
  const { t, i18n } = useTranslation(['common'])
  const dispatch = useAppDispatch()

  const { isLoading, isConvertSuccess, text, translates, isError } =
    useAppSelector((state) => state.ocr)

  const [file, setFile] = useState<UploadFile | null>(null)
  const [errorText, setErrorText] = useState<string>('')
  const [urlFile, setUrlFile] = useState<string>('')

  useEffect(() => {
    if (file) {
      if (file.type === 'application/pdf') {
        convertPdfToUrl(file as any).then((res) => setUrlFile(res))
      } else {
        convertImageToUrl(file as any).then((res) => setUrlFile(res))
      }
    } else {
      setUrlFile('')
    }
  }, [file])

  useEffect(() => {
    return () => {
      onResetDemo()
    }
  }, [])

  useEffect(() => {
    getTextError()
  }, [translates, i18n.language])

  const onConvert = async () => {
    await dispatch(ocrFileToTextAction({ params: { file } })).then(
      (res: any) => {
        if (res?.error) onResetDemo()
      }
    )
  }

  const onResetDemo = async () => {
    await dispatch(resetFileToText())
    setFile(null)
  }

  const getTextError = () => {
    if (!translates) return setErrorText('')

    switch (i18n.language.toString()) {
      case 'en':
        return setErrorText(translates.en)
      case 'ja':
        return setErrorText(translates.ja)
      case 'ko':
        return setErrorText(translates.ko)
      case 'vi':
        return setErrorText(translates.vi)
      default:
        return setErrorText('')
    }
  }

  return (
    <div className="ocr-demo">
      <div className="main-container custom-container" id="demo-ocr">
        <h1 className="text-[46px] text-center font-bold title">
          {t('Optical Character Recognition (OCR)')}
        </h1>
        <p className="text-center leading-6 mt-[18px] mb-[90px] description">
          {t('Convert Image to Text (Extract Text from Image)')}
        </p>

        <div className="scanner-body">
          <div className="wrapper-input">
            <h3 className="title">{t('Optical Character Recognition')}</h3>
            <p className="description">
              {t(
                'Extract text from Images, PDFs, and Documents with one click.'
              )}
            </p>

            {!isConvertSuccess && !isLoading && (
              <div className="form-upload">
                <div className="box-actions">
                  <UploadDragger
                    accept={getAccept([...TYPE_FILE_IMAGE, ...TYPE_FILE_PDF])}
                    onChange={(info) => {
                      setFile(info.file)
                    }}
                    onRemoveFile={() => setFile(null)}
                  />

                  {file && (
                    <div className="box-request-convert">
                      <Button
                        className="explore_more min-w-[124px] h-[36px]"
                        radius
                        onClick={onConvert}
                      >
                        {t('Convert')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isLoading && (
              <div className="wrapper-result loading">
                <div className="result-box">
                  <p className="loading-title">{t('Processing...')}</p>
                  <p className="loading-description">
                    {t('Please wait while the data is being converted')}
                  </p>
                  <Loading loading={isLoading} />
                </div>
              </div>
            )}

            {isConvertSuccess && file && (
              <>
                <div
                  className={classNames('wrapper-result has-input', {
                    error: isError,
                  })}
                >
                  <div className="result-input">
                    {file.type === 'application/pdf' ? (
                      <PDFViewer url={urlFile} />
                    ) : (
                      <div className="box-image">
                        <img src={urlFile} alt="abc" />
                      </div>
                    )}
                  </div>

                  <div className="result-box">
                    <p className="result-title">
                      {isError
                        ? t('OCR Conversion Failed')
                        : t('Conversion Successful!')}
                    </p>
                    <div className="result-content">
                      {isError ? (
                        <div className="result-error">
                          <p className="result-content">{errorText}</p>

                          <Button
                            className="min-w-[124px] h-[36px]"
                            radius
                            onClick={onResetDemo}
                          >
                            {t('Try again')}
                          </Button>
                        </div>
                      ) : (
                        <TextArea
                          className="input-result"
                          autoSize
                          onChange={(e) =>
                            dispatch(setFileToText(e.target.value))
                          }
                          value={text}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {!isError && (
                  <div className="result-action-box">
                    <Button
                      className="explore_more min-w-[124px] h-[36px] basic"
                      radius
                      onClick={onResetDemo}
                    >
                      {t('Back')}
                    </Button>

                    <Button
                      className="explore_more min-w-[124px] h-[36px]"
                      radius
                      onClick={() => handleDownload(text, file?.name)}
                    >
                      {t('Download')}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <p className="note-feature">
          <span>*</span>
          {t(
            'Your privacy is protected! None of the data you submit is transmitted or stored.'
          )}
        </p>
      </div>
    </div>
  )
}

export default OcrDemo
