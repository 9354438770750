import classNames from 'classnames'
import * as faceapi from 'face-api.js'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import IconSuccess from '@/assets/img/Circle_Check.png'
import IconFail from '@/assets/img/Circle_Error.png'
import Button from '@/components/Common/Item/Button'

type DetectFacesProps = {
  file: any
  loadingFace: boolean
  faceSelected: number
  faces: HTMLCanvasElement[]
  listInfo: FaceRecognition.Persons[]
  facesExtracted: boolean
  setFaces: (value: HTMLCanvasElement[]) => void
  setIsVisibleConfirm: (value: boolean) => void
  handleFaceSelected: (value: number) => void
  setFacesExtracted: (value: boolean) => void
  setDetectAllFacesComplete: (value: boolean) => void
}

const DetectFaces: React.FC<DetectFacesProps> = ({
  file,
  loadingFace,
  faceSelected,
  faces,
  listInfo,
  facesExtracted,
  setFaces,
  setIsVisibleConfirm,
  handleFaceSelected,
  setFacesExtracted,
  setDetectAllFacesComplete,
}) => {
  const { t } = useTranslation(['common', 'validation'])
  const imgRef = useRef<HTMLImageElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    return () => {
      setFacesExtracted(false)
    }
  }, [])

  // Detect faces in the image
  const handleImageLoad = async () => {
    if (
      !loadingFace &&
      imgRef.current &&
      canvasRef.current &&
      !facesExtracted
    ) {
      // Make sure the canvas matches the photo dimensions
      const imageElement = imgRef.current
      const canvasElement = canvasRef.current

      canvasElement.width = imageElement.width
      canvasElement.height = imageElement.height

      const ctx = canvasElement.getContext('2d')

      const detections = await faceapi
        .detectAllFaces(imageElement)
        .withFaceLandmarks()
        .withFaceDescriptors()
      faceapi.matchDimensions(canvasElement, {
        width: imageElement.width,
        height: imageElement.height,
      })
      const resizedDetections = faceapi.resizeResults(detections, {
        width: imageElement.width,
        height: imageElement.height,
      })

      // Draw a face recognition frame on the canvas
      if (ctx) {
        ctx.clearRect(0, 0, canvasElement.width, canvasElement.height)
        faceapi.draw.drawDetections(canvasElement, resizedDetections)
        // faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections)
      }

      // Face recognition
      extractFaces(imageElement, detections as any)
    }
  }

  // Func Face recognition
  const extractFaces = (
    image: HTMLImageElement,
    detections: faceapi.WithFaceLandmarks<faceapi.FaceDetection | any>[]
  ) => {
    const faceCanvases: HTMLCanvasElement[] = []

    detections.forEach((detection) => {
      const { x, y, width, height } = detection.detection.box

      // Create a new canvas for each face
      const faceCanvas = document.createElement('canvas')
      const faceCtx = faceCanvas.getContext('2d')

      if (faceCtx) {
        faceCanvas.width = width
        faceCanvas.height = height

        // Cut out the face from the original photo and draw it on the new canvas
        faceCtx.drawImage(image, x, y, width, height, 0, 0, width, height)

        // Save the face canvas
        faceCanvases.push(faceCanvas)
      }
    })

    setFaces(faceCanvases) // Save extracted faces to state
    setFacesExtracted(true) // Marks that the face has been separated to avoid repetition
    setDetectAllFacesComplete(true)
  }

  return (
    <div className="image-box">
      <div className="main-image">
        <div className="image">
          {file && (
            <img
              ref={imgRef}
              src={URL.createObjectURL(file as any)}
              alt="Face Recognition"
              onLoad={handleImageLoad}
              id="yourImageId"
            />
          )}
          <canvas ref={canvasRef} id="yourCanvasId" width={500} height={500} />
        </div>
        <Button
          className="btn-try-again h-[28px] min-w-[95px] mt-[12px]"
          radius
          onClick={() => setIsVisibleConfirm(true)}
        >
          {t('Try again')}
        </Button>
      </div>

      <div className="list-faces">
        {faces.map((faceCanvas, index) => {
          const info = listInfo[index]
          return (
            <div
              key={index}
              className={classNames('face-item', {
                active: faceSelected === index,
                success: info?.success,
                fail: info?.error,
              })}
              onClick={() => handleFaceSelected(index)}
            >
              <img src={faceCanvas.toDataURL()} alt={`Face ${index + 1}`} />

              {info?.success && (
                <img src={IconSuccess} alt="Success" className="icon" />
              )}
              {info?.error && (
                <img src={IconFail} alt="Fail" className="icon" />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DetectFaces
