import './style.scss'

import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import classNames from 'classnames'
import React from 'react'

const { TextArea } = Input

interface CustomTextAreaProps extends TextAreaProps {
  label?: string;
  className?: string;
  error?: string | null;
  required?: boolean;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  label,
  className,
  error,
  required,
  ...props
}) => {
  return (
    <div className={classNames('default-text-area', { error }, className)}>
      {label && (
        <label className="label">
          {required && <span className="required">*</span>}
          {label}
        </label>
      )}
      <TextArea {...props} />

      {error && <p className="text-error">{error}</p>}
    </div>
  )
}

export default CustomTextArea
