import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageFeature from '@/assets/img/gameMetaverse/feature.png'
import ImageIntroduce from '@/assets/img/gameMetaverse/introduce.png'

const Introduce = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      title: t('game_metaverse_feature_title_1'),
      description: t('game_metaverse_feature_description_1'),
    },
    {
      title: t('game_metaverse_feature_title_2'),
      description: t('game_metaverse_feature_description_2'),
    },
    {
      title: t('game_metaverse_feature_title_3'),
      description: t('game_metaverse_feature_description_3'),
    },
    {
      title: t('game_metaverse_feature_title_4'),
      description: t('game_metaverse_feature_description_4'),
    },
    {
      title: t('game_metaverse_feature_title_5'),
      description: t('game_metaverse_feature_description_5'),
    },
    {
      title: t('game_metaverse_feature_title_6'),
      description: t('game_metaverse_feature_description_6'),
    },
  ]

  const renderListItems = (list: { title: string; description: string }[]) => {
    return list.map((item, index) => {
      return (
        <div className="feature-item" key={index}>
          <p className="feature-title">{item.title}</p>
          <p className="feature-description">{item.description}</p>
        </div>
      )
    })
  }

  return (
    <section className="game_metaverse_introduce" id="game_metaverse_introduce">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('What is Game & Metaverse?')}</h2>
          <p className="description">
            {t('game_metaverse_introduce_description')}
          </p>
          <div className="image-intro">
            <img src={ImageIntroduce} alt="What is Digital Transformation?" />
          </div>
        </div>

        <div className="feature">
          <p className="feature-title">{t('Key features of Metaverse')}</p>
          <div className="introduce-list-feature">
            <div className="left">{renderListItems(listItems).slice(0, 3)}</div>
            <div className="image">
              <img src={ImageFeature} alt="Key features of Metaverse" />
            </div>
            <div className="right">
              {renderListItems(listItems).slice(3, 6)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Introduce
