import './style.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImageDifference from '@/assets/img/ocr/difference.png'
import ImageDifference2 from '@/assets/img/ocr/difference2.png'
import Switch from '@/components/Common/Item/Switch'

const Difference = () => {
  const { t } = useTranslation(['common'])
  const [step, toggleStep] = useState<boolean>(false)

  const listFirst = [
    t('Prepare the Document'),
    t('Manually Enter Data'),
    t('Review the Data'),
    t('Edit data'),
    t('Format and Save'),
  ]

  const listSecond = [
    t('Upload File'),
    t('BAP AI processes and converts to text'),
  ]

  const renderSteps = (arr: string[], second?: string) => {
    const elements = arr.map((item, index) => (
      <div key={index} className="step-item">
        <p className="number-index">{index + 1}</p>
        {item}
      </div>
    ))

    return <div className={classNames('list-steps', second)}>{elements}</div>
  }

  const renderListFirst = renderSteps(listFirst)
  const renderListSecond = renderSteps(listSecond, 'second')

  return (
    <section className="difference" id="difference">
      <div className="main-container custom-container">
        <div className="wrapper-title">
          <div className="main-image">
            <img
              src={!step ? ImageDifference2 : ImageDifference}
              alt="Difference"
            />
          </div>
          <div className="title-btn">
            <h2 className="title">{t('Swipe to see difference')}</h2>

            <Switch
              className="big"
              checked={step}
              onChange={(value) => toggleStep(value)}
            />
          </div>
        </div>

        <div className="wrapper-steps">
          {!step ? renderListFirst : renderListSecond}
        </div>
      </div>
    </section>
  )
}

export default Difference
