import './style.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import IconAIFace from '@/assets/svg/ai_face.svg'
import IconAIOCR from '@/assets/svg/ai_ocr.svg'
import IconAIText from '@/assets/svg/ai_text.svg'
import IconAIChatBoxPlatForm from '@/assets/svg/chatbot_platform.svg'
import IconAIChatbotTemplate from '@/assets/svg/chatbot_template.svg'
import IconAIImageGenerator from '@/assets/svg/image_generator.svg'
import IconAILargeLanguage from '@/assets/svg/large_language_model.svg'

const AISection = () => {
  const { t, i18n } = useTranslation(['common'])
  const navigate = useNavigate()

  const listAI = [
    {
      icon: IconAIText,
      title: t('Speech to Text'),
      path: '/speech-to-text',
    },
    {
      icon: IconAIOCR,
      title: t('OCR'),
      path: '/ocr',
    },
    {
      icon: IconAIFace,
      title: t('Face Recognition'),
      path: '/face-recognition',
    },
    {
      icon: IconAIChatBoxPlatForm,
      title: t('Chatbot Platform'),
      path: '/chatbot-platform',
    },
    {
      icon: IconAIChatbotTemplate,
      title: t('Chatbot Template'),
      path: '/chatbot-template',
    },
    {
      icon: IconAILargeLanguage,
      title: t('Large Language Model'),
      path: '/large-language-model',
    },
    {
      icon: IconAIImageGenerator,
      title: t('Image Generator'),
      path: '/image-generator',
    },
  ]

  const renderListAI = listAI.map((item, index) => (
    <div
      className="ai-item"
      key={index}
      onClick={() => navigate(`/${i18n.language}${item.path}`)}
    >
      <div className="icon">
        <img src={item.icon} alt={item.title} />
      </div>

      <p className="title">{item.title}</p>
    </div>
  ))

  return (
    <section className="ai-solutions-service" id="ai-solutions-service">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('marketing:ai_overview:ai_title')}</h2>
          <p className="description">
            {t('marketing:ai_overview:ai_description')}
          </p>
        </div>

        <div className="list-ai">{renderListAI}</div>
      </div>
    </section>
  )
}

export default AISection
