import './style.scss'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'

import { Viewer } from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import * as pdfjs from 'pdfjs-dist'
import React from 'react'

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js'

interface PDFViewerProps {
  url: string
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { CurrentPageInput, GoToNextPageButton, GoToPreviousPage } =
    pageNavigationPluginInstance

  const zoomPluginInstance = zoomPlugin()
  const { ZoomInButton, ZoomOutButton, CurrentScale } = zoomPluginInstance

  return (
    <div className="wrapper-pdf-viewer">
      <div className="toolbar">
        <GoToPreviousPage />
        <CurrentPageInput />
        <GoToNextPageButton />
        <ZoomInButton />
        <ZoomOutButton />
        <CurrentScale />
      </div>

      <Viewer
        fileUrl={url}
        plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
      />
    </div>
  )
}

export default PDFViewer
