/* eslint-disable sonarjs/cognitive-complexity*/
import { UploadFile } from 'antd'
import classNames from 'classnames'
// import dayjs, { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import IconSuccessAll from '@/assets/img/Success_All.png'
import Button from '@/components/Common/Item/Button'
// import DatePicker from '@/components/Common/Item/DatePicker'
import Input from '@/components/Common/Item/Input'
import Loading from '@/components/Common/Item/Loading'
import Notification from '@/components/Common/Item/Notification'
import Select from '@/components/Common/Item/Select'
// import { DATE_FORMAT, DATE_FORMAT_REQUEST } from '@/contants/date-time'
import { FEATURE_TABS } from '@/contants/face-recognition'
import { GENDER_OPTIONS } from '@/contants/form'
import { identificationInfoAction } from '@/states/features/faceRecognition/faceRecognition.action'
import { useAppDispatch } from '@/states/store'
import { canvasToFile } from '@/utils/file'
import { cleanedObj } from '@/utils/form'

import DetectFaces from '../DetectFaces'
import { validationSchema } from '../validation'

type AddNewDataProps = {
  reset: boolean
  currentTab: string
  loadingFace: boolean
  file: UploadFile | File | null
  faces: HTMLCanvasElement[]
  faceSelected: number
  listInfo: FaceRecognition.Persons[]
  isLoading: boolean
  facesExtracted: boolean
  setFaces: (value: HTMLCanvasElement[]) => void
  setFaceSelected: (value: number) => void
  setListInfo: (value: FaceRecognition.Persons[]) => void
  setIsLoading: (value: boolean) => void
  setFacesExtracted: (value: boolean) => void
  setIsVisibleConfirm: (value: boolean) => void
  handleTestImage: () => void
  setDetectAllFacesComplete: (value: boolean) => void
}

const AddNewData: React.FC<AddNewDataProps> = ({
  reset,
  currentTab,
  loadingFace,
  file,
  faces,
  faceSelected,
  listInfo,
  isLoading,
  facesExtracted,
  setFaces,
  setFaceSelected,
  setListInfo,
  setIsLoading,
  setFacesExtracted,
  setIsVisibleConfirm,
  handleTestImage,
  setDetectAllFacesComplete,
}) => {
  const { t, i18n } = useTranslation(['common', 'validation', 'errors'])
  const dispatch = useAppDispatch()

  useEffect(() => {
    formik.validateForm()
  }, [i18n.language])

  useEffect(() => {
    formik.resetForm({})
  }, [reset])

  const initialValues = {
    fullName: '',
    // country: '',
    // birthday: '',
    gender: '',
    // age: '',
    // email: '',
    // phoneNumber: '',
  }

  const handleFaceSelected = (index: number) => {
    setFaceSelected(index)
    formik.setValues(listInfo[index])
  }

  // const getNextFaceSelected = () => {
  //   const nextIndex = faceSelected + 1
  //   if (nextIndex < listInfo.length) return nextIndex
  //   const findIndex = listInfo.findIndex(
  //     (x) => !x.success && !x.fail && !faceSelected
  //   )
  //   return findIndex >= 0 ? findIndex : null
  // }

  const formik = useFormik({
    initialValues: listInfo[faceSelected] || initialValues,
    validationSchema: validationSchema(t),
    onSubmit: async (values, { resetForm }) => {
      const file = await canvasToFile(faces[faceSelected], 'face.png')
      const params = {
        ...cleanedObj(values),
        // birthday: dayjs(values.birthday).format(DATE_FORMAT_REQUEST),
        file,
      }
      setIsLoading(true)
      const result = await dispatch(identificationInfoAction({ params }))
      const newListInfo = [...listInfo]

      if (result.payload.code === 200) {
        if (result.payload.data.errorCode) {
          newListInfo[faceSelected] = {
            ...newListInfo[faceSelected],
            error: result.payload.data.errorCode,
          }
        } else {
          newListInfo[faceSelected] = {
            ...newListInfo[faceSelected],
            success: true,
          }
        }

        setListInfo(newListInfo)
        setIsLoading(false)

        // const nextIndex = getNextFaceSelected()

        // if (nextIndex) {
        //   handleFaceSelected(nextIndex)
        //   resetForm(initialValues as any)
        // }
        resetForm(initialValues as any)
      } else {
        setIsLoading(false)

        if (result.payload?.message?.length > 0) {
          Notification.error(
            t(`${result.payload.message[0].code}`, { ns: 'errors' })
          )
        }
      }
    },
  })

  // const disabledDate = (current: Dayjs | null): boolean => {
  //   return current ? current > dayjs().startOf('day') : false
  // }

  return (
    <div
      className={classNames('wrapper-image-info add-new', {
        hidden: currentTab === FEATURE_TABS.FACE_RECOGNITION,
      })}
    >
      <DetectFaces
        file={file}
        loadingFace={loadingFace}
        faceSelected={faceSelected}
        faces={faces}
        listInfo={listInfo}
        facesExtracted={facesExtracted}
        setFaces={setFaces}
        setIsVisibleConfirm={setIsVisibleConfirm}
        setFacesExtracted={setFacesExtracted}
        handleFaceSelected={handleFaceSelected}
        setDetectAllFacesComplete={setDetectAllFacesComplete}
      />

      <div className="line" />

      <div className="info-box">
        {faces.length > 0 ? (
          isLoading ? (
            <div className="wrapper-request-loading">
              <p className="loading-title">{t('Processing...')}</p>
              <p className="loading-description">
                {t('Processing your data, please hold on..')}
              </p>
              <Loading loading={isLoading} />
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              {listInfo[faceSelected]?.error && (
                <div className="wrapper-error">
                  <p className="massage-error">
                    {t(`${listInfo[faceSelected]?.error}`, {
                      ns: 'errors',
                    })}
                  </p>
                </div>
              )}

              {listInfo[faceSelected]?.success && (
                <div className="wrapper-success">
                  <p className="success-title">
                    {t('Face data added successfully')}
                  </p>
                  <p className="success-content">
                    {t(
                      'The image of has been successfully added to the face recognition system!'
                    )}
                  </p>

                  <img src={IconSuccessAll} alt="Success all" />

                  <Button
                    className="min-w-[106] h-[28]"
                    radius
                    onClick={handleTestImage}
                  >
                    {t('Test Image')}
                  </Button>
                </div>
              )}

              {!listInfo[faceSelected]?.error &&
                !listInfo[faceSelected]?.success && (
                  <div className="form-info-header">
                    <p className="form-title">{t('Add data')}</p>
                    <Button
                      className="min-w-[92px] h-[28px]"
                      radius
                      htmlType="submit"
                      disabled={isLoading || !faces[faceSelected]}
                    >
                      {t('Add new')}
                    </Button>
                  </div>
                )}

              {!listInfo[faceSelected]?.error &&
                !listInfo[faceSelected]?.success && (
                  <div className="form-body">
                    <div className="field-input">
                      <Input
                        inline
                        required
                        label={t('Full name')}
                        placeholder={t('Enter Full name')}
                        name="fullName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.fullName}
                        error={
                          formik.touched.fullName && formik.errors.fullName
                            ? formik.errors.fullName
                            : null
                        }
                      />
                    </div>
                    {/* <div className="field-input">
                      <Input
                        inline
                        required
                        label={t('Country')}
                        placeholder={t('Enter Country')}
                        name="country"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        error={
                          formik.touched.country && formik.errors.country
                            ? formik.errors.country
                            : null
                        }
                      />
                    </div> */}
                    {/* <div className="field-input">
                      <DatePicker
                        inline
                        required
                        label={t('Birthday')}
                        placeholder={t('Select Birthday')}
                        onChange={(value) => {
                          formik.setFieldValue('birthday', value)
                        }}
                        value={formik.values.birthday || null}
                        error={
                          formik.touched.birthday && formik.errors.birthday
                            ? formik.errors.birthday
                            : null
                        }
                        format={DATE_FORMAT}
                        disabledDate={disabledDate}
                      />
                    </div> */}
                    <div className="field-input">
                      <Select
                        inline
                        required
                        label={t('Gender')}
                        placeholder={t('Select Gender')}
                        onChange={(value) =>
                          formik.setFieldValue('gender', value)
                        }
                        options={GENDER_OPTIONS(t)}
                        value={formik.values.gender || null}
                        error={
                          formik.touched.gender && formik.errors.gender
                            ? formik.errors.gender
                            : null
                        }
                      />
                    </div>
                    {/* <div className="field-input">
                      <Input
                        inline
                        required
                        label={t('Age')}
                        placeholder={t('Enter Age')}
                        name="age"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.age}
                        error={
                          formik.touched.age && formik.errors.age
                            ? formik.errors.age
                            : null
                        }
                      />
                    </div> */}
                    {/* <div className="field-input">
                      <Input
                        inline
                        required
                        label={t('Email')}
                        placeholder={t('Enter Email')}
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null
                        }
                      />
                    </div> */}
                    {/* <div className="field-input">
                      <Input
                        inline
                        required
                        label={t('Phone number')}
                        placeholder={t('Enter Phone number')}
                        name="phoneNumber"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        error={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? formik.errors.phoneNumber
                            : null
                        }
                      />
                    </div> */}
                  </div>
                )}
            </form>
          )
        ) : (
          <div className={classNames('wrapper-error')}>
            <p className="massage-error">{t('Face not detected')}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddNewData
