import { useEffect, useState } from 'react'

const useHasWebcam = (): boolean | null => {
  const [hasWebcam, setHasWebcam] = useState<boolean | null>(null)

  useEffect(() => {
    const checkWebcam = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoInputDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        )
        await navigator.mediaDevices.getUserMedia({ video: true })
        setHasWebcam(videoInputDevices.length > 0)
      } catch (error) {
        console.error('Error accessing devices: ', error)
        setHasWebcam(false)
      }
    }

    checkWebcam()
  }, [])

  return hasWebcam
}

export default useHasWebcam
