export const GENDER_OPTIONS = (t: any) => {
  return [
    { label: t('Male', { ns: 'common' }), value: 'MALE' },
    { label: t('Female', { ns: 'common' }), value: 'FEMALE' },
    { label: t('Non-binary', { ns: 'common' }), value: 'NON_BINARY' },
    {
      label: t('Prefer not to say', { ns: 'common' }),
      value: 'PREFER_NOT_TO_SAY',
    },
    { label: t('Other', { ns: 'common' }), value: 'OTHER' },
  ]
}
