import '@/styles/styles.scss'

import { ConfigProvider } from 'antd'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

const PGate = PersistGate as any

import Spinner from '@/components/Common/Item/Spinner'

// import PrivateRouting from './components/Routing/PrivateRoute'
import PublicRoute from './components/Routing/PublicRoute'
import { persistor, store } from './states/store'

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (
    <Provider store={store}>
      <PGate loading={null} persistor={persistor}>
        {<Spinner loading={loading} />}
        <ConfigProvider>
          <Router>
            <PublicRoute />
            {/* <PrivateRouting /> */}
          </Router>
        </ConfigProvider>
      </PGate>
    </Provider>
  )
}

export default App
