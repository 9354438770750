import './style.scss'

import { Modal } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Webcam from 'react-webcam'

import Button from '@/components/Common/Item/Button'
import useHasWebcam from '@/hooks/useHasWebcam'
import { base64ToFile } from '@/utils/file'

import Loading from '../Loading'

interface ModalWebcamProps {
  className?: string
  visible: boolean
  onClose: () => void
  onOk: (file: File) => void
}

const ModalWebcam: React.FC<ModalWebcamProps> = ({
  className,
  visible,
  onClose,
  onOk,
}) => {
  const { t } = useTranslation(['common'])
  const hasWebcam = useHasWebcam()

  const webcamRef = useRef<any>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (hasWebcam) {
      setLoading(true)
    }
  }, [hasWebcam])

  useEffect(() => {
    // Cleanup function to stop the webcam stream
    return () => {
      if (webcamRef.current && webcamRef.current.video.srcObject) {
        const videoTracks = (
          webcamRef.current.video.srcObject as MediaStream
        ).getVideoTracks()
        videoTracks.forEach((track) => track.stop())
      }
    }
  }, [])

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    const file = await base64ToFile(imageSrc, 'photo.jpg', 'image/jpeg')

    onOk(file)
  }, [webcamRef, visible])

  const handleWebcamReady = () => {
    setLoading(false)
  }

  return (
    <Modal
      centered
      className={classNames('modal-webcam', className)}
      title={t('Capture image webcam')}
      open={visible}
      closable={false}
      footer={null}
    >
      <div className={classNames('wrapper-webcam', { loading })}>
        {loading && <Loading loading />}

        {hasWebcam ? (
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            onUserMedia={handleWebcamReady}
          />
        ) : (
          <div className="warning-webcam">
            {t(
              'Webcam not found. Please ensure your webcam is properly connected and enabled.'
            )}
          </div>
        )}

        {!loading && (
          <div className="box-actions">
            <Button
              className="basic btn-close h-[36px] min-w-[70px]"
              radius
              onClick={onClose}
            >
              {t('Close')}
            </Button>

            {hasWebcam && (
              <Button
                className="btn-capture h-[36px] min-w-[86px]"
                radius
                onClick={capturePhoto}
              >
                {t('Capture')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalWebcam
