import { notification } from 'antd'

class Notification {
  static success(text: string) {
    notification.success({
      message: text,
      placement: 'bottomRight',
    })
  }

  // static warning(text: string) {
  // }

  // static info(text: string) {
  // }

  static error(text: string) {
    notification.error({
      message: text,
      placement: 'bottomRight',
    })
  }
}

export default Notification
