import './style.scss'

import classNames from 'classnames'
import React from 'react'

interface CardFeatureProps {
  className?: string;
  image: string;
  title: string;
  description: string;
}

const CardFeature: React.FC<CardFeatureProps> = ({
  className,
  image,
  title,
  description,
}) => {
  return (
    <div className={classNames('card-feature-item', className)}>
      <img src={image} alt={title} className="card-feature-image" />
      <div className="card-feature-content">
        <div className="card-feature-title">{title}</div>
        <div className="card-feature-description">{description}</div>
      </div>
    </div>
  )
}

export default CardFeature
