import './style.scss'

type data = {
  title: string
  description?: string
  image?: string
}

type PRTechnologyProps = {
  title: string
  description?: string
  image?: string
  data?: data[]
}

const PRTechnology: React.FC<PRTechnologyProps> = ({
  title,
  description,
  image,
  data,
}) => {
  const renderListItems = Array.isArray(data)
    ? data.map((item, index) => {
        return (
          <div className="wrapper-technology-item" key={index}>
            {item.image && <img src={item.image} alt={item.title} />}
            <p className="technology-title">{item.title}</p>
          </div>
        )
      })
    : []

  return (
    <section className="pr-technology" id="pr-technology">
      <div className="main-container custom-container">
        <div className="content">
          <h2 className="title">{title}</h2>
          {description && <p className="description">{description}</p>}
          {image && (
            <div className="image-box">
              <img src={image} alt={title} />
            </div>
          )}
        </div>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default PRTechnology
