import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function useOfflineRedirect(): boolean {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)
  const navigate = useNavigate()

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [navigate])

  return isOnline
}

export default useOfflineRedirect
