import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { sendContact } from './contact.api'

export const sendContactAction = createAsyncThunk<
  any,
  Contact.ContactSendPayload
>('contact/sendContact', async ({ params }, { rejectWithValue }) => {
  try {
    const response = await sendContact(params)
    notification.success({
      message: i18next.t('The request was sent successfully', {
        ns: 'success',
      }),
      placement: 'bottomRight',
    })
    return response
  } catch (error: any) {
    if (error.error) {
      notification.error({
        message: i18next.t(`${error.error}`, { ns: 'errors' }),
        placement: 'bottomRight',
      })
    }

    return rejectWithValue(error?.error || error)
  }
})
