import './style.scss'

import { useTranslation } from 'react-i18next'

import IconTech1 from '@/assets/img/blockchain/tech_1.svg'
import IconTech2 from '@/assets/img/blockchain/tech_2.svg'
import IconTech3 from '@/assets/img/blockchain/tech_3.svg'
import IconTech4 from '@/assets/img/blockchain/tech_4.svg'
import IconTech5 from '@/assets/img/blockchain/tech_5.svg'
import IconTech6 from '@/assets/img/blockchain/tech_6.svg'
import ImageTechnologies from '@/assets/img/blockchain/technologies.png'

const Technologies = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      icon: IconTech1,
      label: t('Clients requirements'),
    },
    {
      icon: IconTech2,
      label: t('Service Blueprint'),
    },
    {
      icon: IconTech3,
      label: t('Project Design'),
    },
    {
      icon: IconTech4,
      label: t('Project Development'),
    },
    {
      icon: IconTech5,
      label: t('Testing'),
    },
    {
      icon: IconTech6,
      label: t('Release'),
    },
  ]

  const listContents = [
    {
      title: t('blockchain_technology_title_1'),
      description: t('blockchain_technology_description_1'),
    },
    {
      title: t('blockchain_technology_title_2'),
      description: t('blockchain_technology_description_2'),
    },
    {
      title: t('blockchain_technology_title_3'),
      description: t('blockchain_technology_description_3'),
    },
    {
      title: t('blockchain_technology_title_4'),
      description: t('blockchain_technology_description_4'),
    },
    {
      title: t('blockchain_technology_title_5'),
      description: t('blockchain_technology_description_5'),
    },
    {
      title: t('blockchain_technology_title_6'),
      description: t('blockchain_technology_description_6'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="card-tech-item" key={index}>
        <img src={item.icon} alt={item.label} />
        <p className="card-label">{item.label}</p>
      </div>
    )
  })

  const renderListContents = listContents.map((item, index) => {
    return (
      <div className="content-item" key={index}>
        <p className="content-title">{item.title}</p>
        <p className="content-description">{item.description}</p>
      </div>
    )
  })

  return (
    <section className="blockchain_technologies" id="blockchain_technologies">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">
            {t('Navigating Our Blockchain Technology Development Process')}
          </h2>
          <div className="list-tech-cards">{renderListItems}</div>
          <div className="image-technologies">
            <img
              src={ImageTechnologies}
              alt="Navigating Our Blockchain Technology Development Process"
            />
          </div>
        </div>

        <div className="list-contents">{renderListContents}</div>
      </div>
    </section>
  )
}

export default Technologies
