import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import i18next from 'i18next'

import { getInfoAuthenticate } from './thirdParty.api'

export const getInfoAuthenticateAction = createAsyncThunk<any, any>(
  'thirdParty/getInfoAuthenticate',
  async ({ rejectWithValue }) => {
    try {
      return await getInfoAuthenticate()
    } catch (error: any) {
      if (error.error) {
        notification.error({
          message: i18next.t(`${error.error}`, { ns: 'errors' }),
          placement: 'bottomRight',
        })
      }

      return rejectWithValue(error?.error || error)
    }
  }
)
