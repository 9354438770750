import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageIntroduce1 from '@/assets/img/blockchain/introduce_1.png'
import ImageIntroduce2 from '@/assets/img/blockchain/introduce_2.png'
import ImageIntroduce3 from '@/assets/img/blockchain/introduce_3.png'
import ImageIntroduce4 from '@/assets/img/blockchain/introduce_4.png'
import ImageIntroduce5 from '@/assets/img/blockchain/introduce_5.png'
import ImageIntroduce6 from '@/assets/img/blockchain/introduce_6.png'
import ImageIntroduce7 from '@/assets/img/blockchain/introduce_7.png'
import ImageIntroduce8 from '@/assets/img/blockchain/introduce_8.png'
import ImageIntroduce9 from '@/assets/img/blockchain/introduce_9.png'
import CardFeature from '@/components/Common/Item/CardFeature'

const Introduce = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageIntroduce1,
      title: t('blockchain_introduce_title_1'),
      description: t('blockchain_introduce_description_1'),
    },
    {
      image: ImageIntroduce2,
      title: t('blockchain_introduce_title_2'),
      description: t('blockchain_introduce_description_2'),
    },
    {
      image: ImageIntroduce3,
      title: t('blockchain_introduce_title_3'),
      description: t('blockchain_introduce_description_3'),
    },
    {
      image: ImageIntroduce4,
      title: t('blockchain_introduce_title_4'),
      description: t('blockchain_introduce_description_4'),
    },
    {
      image: ImageIntroduce5,
      title: t('blockchain_introduce_title_5'),
      description: t('blockchain_introduce_description_5'),
    },
    {
      image: ImageIntroduce6,
      title: t('blockchain_introduce_title_6'),
      description: t('blockchain_introduce_description_6'),
    },
    {
      image: ImageIntroduce7,
      title: t('blockchain_introduce_title_7'),
      description: t('blockchain_introduce_description_7'),
    },
    {
      image: ImageIntroduce8,
      title: t('blockchain_introduce_title_8'),
      description: t('blockchain_introduce_description_8'),
    },
    {
      image: ImageIntroduce9,
      title: t('blockchain_introduce_title_9'),
      description: t('blockchain_introduce_description_9'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <CardFeature
        key={index}
        image={item.image}
        title={item.title}
        description={item.description}
      />
    )
  })

  return (
    <section className="blockchain_introduce" id="blockchain_introduce">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">
            {t('Blockchain Solutions Across Various Sectors')}
          </h2>
        </div>

        <div className="introduce-list-cards">{renderListItems}</div>
      </div>
    </section>
  )
}

export default Introduce
