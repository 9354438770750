import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/faceRecognition/banner.png'
import ImageFeature1 from '@/assets/img/faceRecognition/feature_1.png'
import ImageFeature2 from '@/assets/img/faceRecognition/feature_2.png'
import ImageFeature3 from '@/assets/img/faceRecognition/feature_3.png'
import ImageFeature4 from '@/assets/img/faceRecognition/feature_4.png'
import ImageFeature5 from '@/assets/img/faceRecognition/feature_5.png'
import Button from '@/components/Common/Item/Button'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import useGetTextLanguage from '@/hooks/useGetTextLanguage'
import Banner from '@/templates/PRBanner'
import FAQ from '@/templates/PRFAQ'
import Features from '@/templates/PRFeatures'

import FaceRecognitionDemo from './Banner/FaceRecognition'
import StepBySteps from './StepBySteps'

const AIFaceRecognition: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  const dataBanner = {
    titleVi: t('Face Recognition'),
    titleEn: t('Face Recognition'),
    titleJa: t('Face Recognition'),
    titleKo: t('Face Recognition'),
    descriptionVi: t('face_recognition_description'),
    descriptionEn: t('face_recognition_description'),
    descriptionJa: t('face_recognition_description'),
    descriptionKo: t('face_recognition_description'),
    image: ImageBanner,
  }

  const dataFeature = {
    titleVi: t('BAP AIs Advanced Technology Powers Face Recognition'),
    titleEn: t('BAP AIs Advanced Technology Powers Face Recognition'),
    titleJa: t('BAP AIs Advanced Technology Powers Face Recognition'),
    titleKo: t('BAP AIs Advanced Technology Powers Face Recognition'),
    items: [
      {
        titleVi: t('face_recognition_features_title_1'),
        titleEn: t('face_recognition_features_title_1'),
        titleJa: t('face_recognition_features_title_1'),
        titleKo: t('face_recognition_features_title_1'),
        descriptionVi: t('face_recognition_features_description_1'),
        descriptionEn: t('face_recognition_features_description_1'),
        descriptionJa: t('face_recognition_features_description_1'),
        descriptionKo: t('face_recognition_features_description_1'),
        image: ImageFeature1,
      },
      {
        titleVi: t('face_recognition_features_title_2'),
        titleEn: t('face_recognition_features_title_2'),
        titleJa: t('face_recognition_features_title_2'),
        titleKo: t('face_recognition_features_title_2'),
        descriptionVi: t('face_recognition_features_description_2'),
        descriptionEn: t('face_recognition_features_description_2'),
        descriptionJa: t('face_recognition_features_description_2'),
        descriptionKo: t('face_recognition_features_description_2'),
        image: ImageFeature2,
      },
      {
        titleVi: t('face_recognition_features_title_3'),
        titleEn: t('face_recognition_features_title_3'),
        titleJa: t('face_recognition_features_title_3'),
        titleKo: t('face_recognition_features_title_3'),
        descriptionVi: t('face_recognition_features_description_3'),
        descriptionEn: t('face_recognition_features_description_3'),
        descriptionJa: t('face_recognition_features_description_3'),
        descriptionKo: t('face_recognition_features_description_3'),
        image: ImageFeature3,
      },
      {
        titleVi: t('face_recognition_features_title_4'),
        titleEn: t('face_recognition_features_title_4'),
        titleJa: t('face_recognition_features_title_4'),
        titleKo: t('face_recognition_features_title_4'),
        descriptionVi: t('face_recognition_features_description_4'),
        descriptionEn: t('face_recognition_features_description_4'),
        descriptionJa: t('face_recognition_features_description_4'),
        descriptionKo: t('face_recognition_features_description_4'),
        image: ImageFeature4,
      },
      {
        titleVi: t('face_recognition_features_title_5'),
        titleEn: t('face_recognition_features_title_5'),
        titleJa: t('face_recognition_features_title_5'),
        titleKo: t('face_recognition_features_title_5'),
        descriptionVi: t('face_recognition_features_description_5'),
        descriptionEn: t('face_recognition_features_description_5'),
        descriptionJa: t('face_recognition_features_description_5'),
        descriptionKo: t('face_recognition_features_description_5'),
        image: ImageFeature5,
      },
    ],
  }

  const dataFAQ = {
    titleVi: t('Frequently Asked Questions about Face Recognition with BAP AI'),
    titleEn: t('Frequently Asked Questions about Face Recognition with BAP AI'),
    titleJa: t('Frequently Asked Questions about Face Recognition with BAP AI'),
    titleKo: t('Frequently Asked Questions about Face Recognition with BAP AI'),
    items: [
      {
        titleVi: t('face_recognition_faq_title_1'),
        titleEn: t('face_recognition_faq_title_1'),
        titleJa: t('face_recognition_faq_title_1'),
        titleKo: t('face_recognition_faq_title_1'),
        descriptionVi: t('face_recognition_faq_description_1'),
        descriptionEn: t('face_recognition_faq_description_1'),
        descriptionJa: t('face_recognition_faq_description_1'),
        descriptionKo: t('face_recognition_faq_description_1'),
      },
      {
        titleVi: t('face_recognition_faq_title_2'),
        titleEn: t('face_recognition_faq_title_2'),
        titleJa: t('face_recognition_faq_title_2'),
        titleKo: t('face_recognition_faq_title_2'),
        descriptionVi: t('face_recognition_faq_description_2'),
        descriptionEn: t('face_recognition_faq_description_2'),
        descriptionJa: t('face_recognition_faq_description_2'),
        descriptionKo: t('face_recognition_faq_description_2'),
      },
      {
        titleVi: t('face_recognition_faq_title_3'),
        titleEn: t('face_recognition_faq_title_3'),
        titleJa: t('face_recognition_faq_title_3'),
        titleKo: t('face_recognition_faq_title_3'),
        descriptionVi: t('face_recognition_faq_description_3'),
        descriptionEn: t('face_recognition_faq_description_3'),
        descriptionJa: t('face_recognition_faq_description_3'),
        descriptionKo: t('face_recognition_faq_description_3'),
      },
      {
        titleVi: t('face_recognition_faq_title_4'),
        titleEn: t('face_recognition_faq_title_4'),
        titleJa: t('face_recognition_faq_title_4'),
        titleKo: t('face_recognition_faq_title_4'),
        descriptionVi: t('face_recognition_faq_description_4'),
        descriptionEn: t('face_recognition_faq_description_4'),
        descriptionJa: t('face_recognition_faq_description_4'),
        descriptionKo: t('face_recognition_faq_description_4'),
      },
      {
        titleVi: t('face_recognition_faq_title_5'),
        titleEn: t('face_recognition_faq_title_5'),
        titleJa: t('face_recognition_faq_title_5'),
        titleKo: t('face_recognition_faq_title_5'),
        descriptionVi: t('face_recognition_faq_description_5'),
        descriptionEn: t('face_recognition_faq_description_5'),
        descriptionJa: t('face_recognition_faq_description_5'),
        descriptionKo: t('face_recognition_faq_description_5'),
      },
      {
        titleVi: t('face_recognition_faq_title_6'),
        titleEn: t('face_recognition_faq_title_6'),
        titleJa: t('face_recognition_faq_title_6'),
        titleKo: t('face_recognition_faq_title_6'),
        descriptionVi: t('face_recognition_faq_description_6'),
        descriptionEn: t('face_recognition_faq_description_6'),
        descriptionJa: t('face_recognition_faq_description_6'),
        descriptionKo: t('face_recognition_faq_description_6'),
      },
    ],
  }

  const dataFeatureItems = dataFeature.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  const dataFAQItems = dataFAQ.items.map((item) => ({
    ...item,
    title: useGetTextLanguage('title', item),
    description: useGetTextLanguage('description', item),
  }))

  return (
    <Layout>
      <Banner
        title={useGetTextLanguage('title', dataBanner)}
        description={useGetTextLanguage('description', dataBanner)}
        image={dataBanner.image}
        button={
          <Button
            className="explore_more min-w-[245px] h-[48px]"
            onClick={() => {
              scrollToSection('demo-face-recognition')
            }}
          >
            {t('Upload file')}
          </Button>
        }
      >
        <FaceRecognitionDemo />
      </Banner>
      <StepBySteps />
      <Features
        title={useGetTextLanguage('title', dataFeature)}
        data={dataFeatureItems}
        isBackground
      />
      <FAQ title={useGetTextLanguage('title', dataFAQ)} data={dataFAQItems} />
    </Layout>
  )
}

export default AIFaceRecognition
